import React, { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { setIn } from 'final-form';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { callApi } from '../../services/api';
import * as actions from "../../redux/action"
import { connect } from 'react-redux';


const SignUp = ({ setTitle }) => {

    useEffect(() => {
        setTitle('Sign Up')
    }, [])

    const navigate = useNavigate();

    const validationSchema = yup.object({
        first_name: yup.string().required("First Name is required"),
        last_name: yup.string().required("Last Name is required"),
        email: yup.string().email("Enter valid email").required("Email is required"),
        password: yup.string().required("Password is required")
            .matches(
                "(?=.*[0-9])",
                "Must Contain One Number."
            ).matches(
                "(?=.*[Link-z])",
                "Must Contain One Lowercase."
            ).matches(
                "(?=.*[A-Z])",
                "Must Contain One Uppercase."
            ).matches(
                ".{8,32}",
                "Must Contain 8 Characters."
            ),
        confirmPassword: yup.string().required("Confirm password is required")
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    })

    const validateForm = (schema) => async values => {
        if (typeof schema === 'function')
            schema = schema();
        try {
            await schema.validate(values, { abortEarly: false });
        } catch (e) {
            return e.inner.reduce((errors, error) => {
                return setIn(errors, error.path, error.message);
            }, {});
        }
    }

    const validate = validateForm(validationSchema)
    const onSubmit = (values) => {
        delete values.confirmPassword;

        callApi.post('user/register', values).then((response) => {
            if (response.status) {
                navigate("/login")
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div className='nftm-forms-main'>
                <img className='nftm-forms-logo' src='/images/CodelessNFT_Logo_Light.png' alt='' />
                <div className='nftm-form-content signup-cont'>
                    <h2>Create Your Account</h2>
                    <span className="trail-start">Start your free 14-day trial below.</span>
                    <div className='divider-forms'></div>
                    <Form
                        onSubmit={onSubmit}
                        validate={validate}
                        render={({ handleSubmit, values }) => {

                            return (
                                <form onSubmit={handleSubmit}>
                                    <Field name={`first_name`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>First Name</label>
                                                <input {...input} type="text" placeholder={`First Name`} />
                                                {meta.error &&
                                                    meta.touched && (
                                                        <span className="error-msg">
                                                            {meta.error}
                                                        </span>
                                                    )}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name={`last_name`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Last Name</label>
                                                <input {...input} type="text" placeholder={`Last Name`} />
                                                {meta.error &&
                                                    meta.touched && (
                                                        <span className="error-msg">
                                                            {meta.error}
                                                        </span>
                                                    )}
                                            </div>

                                        )}
                                    </Field>
                                    <Field name={`email`}>
                                        {({ input, meta }) => {
                                           return  <div>
                                                {/* <label>Email</label> */}
                                                <input {...input} type="text" placeholder={`Email`} autoComplete="off"/>
                                                {meta.error &&
                                                    meta.touched && (
                                                        <span className="error-msg">
                                                            {meta.error}
                                                        </span>
                                                    )}
                                            </div>
                                        }}
                                    </Field>
                                    <Field name={`password`}>
                                        {({ input, meta }) => (
                                            <div>
                                                {/* <label>Password</label> */}
                                                <input {...input} type="password" placeholder={`Password`} autoComplete="off" />
                                                {meta.error && meta.touched && (
                                                    <div className="error-msg">
                                                        {meta.error}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name={`confirmPassword`}>
                                        {({ input, meta }) => (
                                            <div>
                                                {/* <label>Confirm Password</label> */}
                                                <input {...input} type="password" placeholder={`Confirm Password`} />
                                                {meta.error &&
                                                    meta.touched && (
                                                        <span className="error-msg">
                                                            {meta.error}
                                                        </span>
                                                    )}
                                            </div>
                                        )}
                                    </Field>
                                    <div className='passport-requirements'>
                                        <h3>Password requirements:</h3>
                                        <div className="form-check">
                                            {values && values.password && values.password.length > 8 ?
                                                <img src='/images/check.png' checked alt="" /> :
                                                <img src='/images/remove.png' alt="" />}
                                            <label className="form-check-label" htmlFor="exampleRadios1">
                                                Minimum 8 characters
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            {values && values.password && values.password.match("(?=.*[0-9])") ?
                                                <img src='/images/check.png' checked alt="" /> :
                                                <img src='/images/remove.png' alt="" />}
                                            <label className="form-check-label" htmlFor="exampleRadios1">
                                                At least one number
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            {values && values.password && values.password.match(".*[Link-z].*") ?
                                                <img src='/images/check.png' checked alt="" /> :
                                                <img src='/images/remove.png' alt="" />}
                                            <label className="form-check-label" htmlFor="exampleRadios1">
                                                At least one lowercase letter
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            {values && values.password && values.password.match(".*[A-Z].*") ?
                                                <img src='/images/check.png' checked alt="" /> :
                                                <img src='/images/remove.png' alt="" />}
                                            <label className="form-check-label" htmlFor="exampleRadios1">
                                                At least one uppercase letter
                                            </label>
                                        </div>
                                    </div>
                                    <input type="submit" className='btn login-btn' value="CREATE YOUR ACCOUNT" />
                                    <div className='signup-footer'>
                                        <span className='signup-agre'>By signing up you agree to our  <Link to="/privacy-policy"> Privacy Policy </Link> and <Link to="/terms-of-service"> Terms of Service</Link>.</span>
                                        <div className='signup-create-link'>
                                            <span>Already have an account?</span>
                                            <Link to="/login">Log in here</Link>
                                        </div>
                                    </div>
                                </form>
                            )
                        }}
                    />
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setTitle: (title) => dispatch(actions.setTitle(title)),
});

export default connect(null, mapDispatchToProps)(SignUp)


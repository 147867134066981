import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import * as actions from "../../redux/action"
import { callApi } from '../../services/api';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';


const MyAccount = ({
  updateUserData,
  setTitle,
  userData,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userDataError, setUserDataError] = useState({});
  const [buttonStyle, setButtonStyle] = useState(true);

  const onSubmit = (values, form) => {
    const id = token?.id
    values.id = id
    delete values.confirmPassword

  }

  useEffect(() => {
    setToken(userData);
  }, [userData])

  useEffect(() => {
    setTitle('Account');
  }, [])


  const passwordRequired = () => {
    if (newPassword === '') {
      setUserDataError(
        {
          ...userDataError,
          password: "Password is required",
        })
    }
  }

  const confirmPasswordRequired = () => {
    if (confirmPassword === '') {
      setUserDataError(
        {
          ...userDataError,
          confirmPassword: "Confirm password is required",
        })
    }
  }

  const changeUserData = (e, key) => {
    setToken({
      ...token,
      [key]: e.target.value,
    });
    delete userDataError[key];
  }

  const userDataErrors = (key, value) => {
    if (!token[key]) {
      setUserDataError({
        ...userDataError,
        [key]: [
          `The ${value} field is required.`
        ]
      })
    }
  }



  const handleUpdateMyAccount = () => {
    let values = {
      first_name: token.first_name,
      last_name: token.last_name,
      email: token.email,
    }
    if (showPassword) {
      callApi.post('user/update-password', {
        password: newPassword,
        password_confirmation: confirmPassword,
      }).then((response) => {
        let notyf = new Notyf();
        if (response.status === 200) {
          notyf.success(response.data.message)
          setNewPassword('');
          setConfirmPassword('');
        }
      })
        .catch((error) => {
          setUserDataError(
            newPassword === confirmPassword ?
              {
                ...error.response.data,
              } : {
                ...userDataError,
                confirmPassword: "The password confirmation does not match."
              })
        });
    }

    callApi.post('user/update-profile', values).then((res) => {
      let notyf = new Notyf();
      if (res.status === 200) {
        updateUserData(values)
        notyf.success(res.data.message);
      }
    })
      .catch((error) => {
        setUserDataError(
          {
            ...userDataError,
            ...error.response.data
          }
        )
      });
  }

  return (
    <>
      <div className="content-main">
        <div className="left-menu-button-box">
          <button className={`myAccountBtn ${buttonStyle ? 'btn-style' : ''}`} onClick={() => setButtonStyle(true)}>MY ACCOUNT</button>
          {/* <button className={`myAccountBtn ${buttonStyle ? '' : 'btn-style'}`} onClick={()=>setButtonStyle(false)}>BILLING / PLAN </button> */}
        </div>
        <div className='myAccount-cont'>
          <div className='contact-info'>
            <h3>Contact Information</h3>
          </div>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form }) => {
              return (
                <div>
                  <form onSubmit={handleSubmit} className='my-account-form'>
                    <Field name={`first_name`}>
                      {({ input, meta }) => (
                        <div className='dash-input' htmlFor="FirstName">
                          <label>FIRST NAME</label>
                          <input
                            {...input}
                            value={token?.first_name}
                            type="text"
                            placeholder="First Name"
                            onChange={(e) => changeUserData(e, 'first_name')}
                            onBlur={() => userDataErrors('first_name', 'first name')}
                          />
                          {userDataError.first_name ?
                            <div className="error-msg">
                              {userDataError.first_name[0]}
                            </div> : <></>
                          }
                        </div>
                      )}
                    </Field>
                    <Field name={`last_name`}>
                      {({ input, meta }) => (
                        <div className='dash-input' htmlFor="LastName">
                          <label>LAST NAME</label>
                          <input
                            {...input}
                            value={token?.last_name}
                            type="text"
                            placeholder="Last Name"
                            onChange={(e) => changeUserData(e, 'last_name')}
                            onBlur={() => userDataErrors('last_name', 'last name')}
                          />
                          {userDataError.last_name ?
                            <div className="error-msg">
                              {userDataError.last_name[0]}
                            </div> : <></>
                          }
                        </div>
                      )}
                    </Field>
                    <div className='contact-info login-informtion'>
                      <h3>Login Information</h3>
                    </div>
                    <Field name={`email`}>
                      {({ input, meta }) => (
                        <div className='dash-input' htmlFor="email">
                          <label>EMAIL ADDRESS</label>
                          <input
                            {...input}
                            value={token?.email}
                            type="text"
                            placeholder="Email"
                            onChange={(e) => changeUserData(e, 'email')}
                            onBlur={() => userDataErrors('email', 'email')}
                          />
                          {userDataError.email ?
                            <div className="error-msg">
                              {userDataError.email[0]}
                            </div> : <></>
                          }
                        </div>
                      )}
                    </Field>
                    {showPassword && (
                      <>
                        <Field name="password">
                          {({ input, meta }) => (
                            <div className='dash-input' htmlFor="password">
                              <label>Password</label>
                              <input
                                {...input}
                                value={newPassword}
                                type="password"
                                placeholder="New Password"
                                onChange={(e) => {
                                  setNewPassword(e.target.value);
                                  delete userDataError["password"];
                                }}
                                onBlur={passwordRequired}
                              />
                              {userDataError.password ?
                                <div className="error-msg">
                                  {userDataError.password}
                                </div> : <></>
                              }
                            </div>
                          )}
                        </Field>
                        <Field name="confirmPassword">
                          {({ input, meta }) => (
                            <div className='dash-input' htmlFor="confirmPassword">
                              <label>Confirm Password</label>
                              <input
                                {...input}
                                value={confirmPassword}
                                type="password"
                                placeholder="Confirm Password"
                                onChange={(e) => {
                                  setConfirmPassword(e.target.value);
                                  delete userDataError["confirmPassword"];
                                }}
                                onBlur={confirmPasswordRequired}
                              />
                              {userDataError.confirmPassword ?
                                <div className="error-msg">
                                  {userDataError.confirmPassword}
                                </div> : <></>
                              }
                            </div>
                          )}
                        </Field>
                      </>
                    )}
                    <button className='change-password-btn'
                      onClick={() => setShowPassword(!showPassword)}
                    >CHANGE PASSWORD</button>
                    <input type="submit" className=' save-btn' value="SAVE" onClick={handleUpdateMyAccount} />
                  </form >
                </div>
              )
            }}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  userData: state?.auth?.userData,
})

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (value) => dispatch(actions.updateUserData(value)),
  setTitle: (title) => dispatch(actions.setTitle(title)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)

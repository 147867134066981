import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTimeoutId, exportCollection } from "../redux/action";

export function useExport(id) {
  const showProgress = useSelector((state) => state.collectionReducer.showProgress)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showProgress.sub_status) {
      return
    }

    let collcetionsId = Object.keys(showProgress).filter(item => item !== id)
    collcetionsId.forEach((e) => {
      if (!!showProgress[e]?.exportStatusData.sub_status && !!showProgress[e]?.timeoutId) {
        const { sub_status } = showProgress[e]?.exportStatusData;

        if (sub_status?.status === '1' || sub_status?.status === '2') {
          dispatch(exportCollection({ collectionId: e }));
          clearInterval(showProgress[e]?.timeoutId);
          dispatch(changeTimeoutId({
            value: null,
            show: false,
            changeCollectionId: e,
          }))
        }
      }
    })
  })
}

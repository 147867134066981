import { configConstants } from "../../constants/config";

const initialState = {
    title: '',
};

const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case configConstants.CHANGE_TITLE:
            return {
                ...state,
                title: action.payload
            }

        default: return state
    }
}

export default configReducer;

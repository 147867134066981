export const collectionDataType = {
    GET_COLLECTION_DATA: "GET_COLLECTION_DATA",
    GET_UNACTIVE_COLLECTION_DATA: "GET_UNACTIVE_COLLECTION_DATA",
    CREATE_COLLECTION_DATA: "CREATE_COLLECTION_DATA",
    EDIT_COLLECTION_DATA: "EDIT_COLLECTION_DATA",
    UPDATE_IMAGE_DATA: "UPDATE_IMAGE_DATA",
    DELETE_COLLECTION: "DELETE_COLLECTION",
    IMAGE_COLLECTION_DATA: "IMAGE_COLLECTION_DATA",
    GET_IMAGE_COLLECTION_DATA: "GET_IMAGE_COLLECTION_DATA",
    USER_DATA_TYPE: "USER_DATA_TYPE",
    CREATE_CHIP_DATA: "CREATE_CHIP_DATA",
    GET_CHIP_DATA: "GET_CHIP_DATA",
    CREATE_CHARACTER_DATA: "CREATE_CHARACTER_DATA",
    GET_CHARACTER_DATA: "GET_CHARACTER_DATA",
    DELETE_CHARACTER_DATA: "DELETE_CHARACTER_DATA",
    GET_TRAIT_CARD_DATA: "GET_TRAIT_CARD_DATA",
    DELETE_TRAIT_CARD: "DELETE_TRAIT_CARD",
    CREATE_TRAIT_CARD_COLLECTION: "CREATE_TRAIT_CARD_COLLECTION",
    DRAG_AND_DROP_TRAIT_CARD: "DRAG_AND_DROP_TRAIT_CARD",
    CHANGE_SELECTED_ITEM: "CHANGE_SELECTED_ITEM",
    CREATE_TRAIT_TAGS: "CREATE_TRAIT_TAGS",
    CLEAR_IMAGES_COLLECTION: "CLEAR_IMAGES_COLLECTION",
    DELETE_TRAIT_TAGS: "DELETE_TRAIT_TAGS",
    UPADATE_RARITY_SETTINGS: "UPADATE_RARITY_SETTINGS",
    UPDATE_IMAGE_VARIATION_NAME: "UPDATE_IMAGE_VARIATION_NAME",
    UPDATE_TRAIT_POSITION: "UPDATE_TRAIT_POSITION",
    CLEAR_TRAITS_DATA: "CLEAR_TRAITS_DATA",
    CREATE_IMAGE_COLLECTION: "CREATE_IMAGE_COLLECTION",
    GET_PREVIEW_COLLECTION: "GET_PREVIEW_COLLECTION",
    EXPORT_STATUS: "EXPORT_STATUS",
    EXPORT_DATA: "EXPORT_DATA",
    NOT_VALID_IMAGES: "NOT_VALID_IMAGES",
    GET_PREVIEW_COLLECTION_IMAGES: "GET_PREVIEW_COLLECTION_IMAGES",
    SHOW_PROGRESS: "SHOW_PROGRESS",
    CHANGE_TIMEOUT_ID: "CHANGE_TIMEOUT_ID",
    DELETE_EXPORT_STATUS_DATA: "DELETE_EXPORT_STATUS_DATA",
}


import update from 'immutability-helper'
import { useCallback } from 'react'
import { Card } from './Card.js'
import { TraitsLeftMenuList } from './style.js'

const Container = ({
    traitsCard,
    setTraitsCard,
    onDragEnd,
    changeBackground,
    setImage,
}) => {
    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setTraitsCard((prevCards) => ({
            ...prevCards,
            cards: update(prevCards.cards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards.cards[dragIndex]],
                ]
            }).map((item, index) => {
                item.position = index + 1;
                return item;
            })
        }
        ))
    }, [setTraitsCard])

    const renderCard = useCallback((cards, index) => {
        return (
            <Card
                key={cards?.id}
                index={index}
                position={cards?.position}
                name={cards?.name}
                onDragEnd={onDragEnd}
                moveCard={moveCard}
                id={cards?.id}
                selected={traitsCard.selectedTrait}
                changeBackground={changeBackground}
                rarity={cards?.rarity}
                setImage={setImage}
            />
        )
    }, [changeBackground, moveCard, onDragEnd, setImage, traitsCard.selectedTrait])
    return (
        <>
            <TraitsLeftMenuList>
                <h5 className='traits-dashbord-title'>TRAITS</h5>
                {traitsCard?.cards?.map((cards, i) => renderCard(cards, i))}
            </TraitsLeftMenuList>
        </>
    )
}

export default Container;

import React from 'react';
import { Table } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


const DoughnutChart = (props) => {
  // const doughnutOption = {
  //     animationEnabled: true,
  //     backgroundColor: "rgb(247 248 251)",
  //     color: '#5260E6',
  //     subtitles: [{
  //         text: "1,403",
  //         verticalAlign: "center",
  //         fontColor: "#5260E6",
  //         fontFamily: "Poppins Bold",
  //         color: '#5260E6',
  //         fontSize: 30,
  //         dockInsidePlotArea: true
  //     }],
  //     data: [{
  //         type: "doughnut",
  //         dataPoints: [
  //             { y: 70, color: '#5260E6', },
  //             { y: 30, color: '#D8DEE9', },
  //         ]
  //     }]
  // }
  ChartJS.register(ArcElement, Tooltip, Legend);
  const data = {
    datasets: [
      {
        text: 'hello',
        data: [12, 19],
        backgroundColor: [
          '#D8DEE9',
          '#5260E6',
        ],
        // weight:1,
        cutout: 75
      },
    ],

  };

  return (
    <>


      <div className="doughnut-chart">
        <div className='row'>
          <div className='col-lg-3 col-md-4 col-sm-12 dought-chart-left'>
            <h5 className='dought-table-hdg'>{props.name}</h5>
            <div className='dought-chart-left-cont'>
              {/* <div className='trail-hide-1'></div> */}
              <div className="doughnut-chart-main">
                <div className='doughnut-chart-text'>
                  1,403
                </div>
                <Doughnut data={data}
                  width={200}
                  height={200}
                  options={{
                    maintainAspectRatio: false,
                    responsive: false,
                    borderWidth: 0,

                  }} />
              </div>
            </div>
          </div>
          <div className='col-lg-9 col-md-8 col-sm-12 dought-chart-right'>
            <h5 className='dought-table-hdg'>WITHDRAWL HISTORY</h5>
            <div className='dought-chart-right-cont table-responsive'>
              <Table >
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>AMOUNT</th>
                    <th>WALLET ADDRESS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>04/12/24</td>
                    <td>14.42 ETH~$245.98</td>
                    <td>0x3981F41...F0762a48</td>
                  </tr>
                  <tr>
                    <td>04/12/24</td>
                    <td>14.42 ETH~$245.98</td>
                    <td>0x3981F41...F0762a48</td>
                  </tr>
                  <tr>
                    <td>04/12/24</td>
                    <td>14.42 ETH~$245.98</td>
                    <td>0x3981F41...F0762a48</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className='recent-transction-table'>
          <h5 className='dought-table-hdg'>RECENT TRANSACTIONS</h5>
          <div className='recent-transction-table-cont table-responsive'>
            <Table className='border-top-0'>
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>AMOUNT</th>
                  <th>WALLET ADDRESS</th>
                  <th>NFT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>04/12/24</td>
                  <td>14.42 ETH~$245.98</td>
                  <td>0x3981F41...F0762a48</td>
                  <td>4,029</td>
                </tr>
                <tr>
                  <td>04/12/24</td>
                  <td>14.42 ETH~$245.98</td>
                  <td>0x3981F41...F0762a48</td>
                  <td>4,029</td>
                </tr>
                <tr>
                  <td>04/12/24</td>
                  <td>14.42 ETH~$245.98</td>
                  <td>0x3981F41...F0762a48</td>
                  <td>4,029</td>
                </tr>
                <tr>
                  <td>04/12/24</td>
                  <td>14.42 ETH~$245.98</td>
                  <td>0x3981F41...F0762a48</td>
                  <td>4,029</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
};

export default DoughnutChart;

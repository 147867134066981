import React, { useState } from 'react'

import { Row, Col, Button } from 'react-bootstrap'
import { StyledModal, StyledContainer } from '../styles'

const Confirm = ({
  confirmButton,
  cancelButton,
  onConfirm,
  onCancel,
  content,
  button,
  width,
}) => {
  const [showModal, setShowModal] = useState(false)

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }

    setShowModal(false)
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }

    setShowModal(false)
  }

  const renderButtons = () => {
    const renderConfirmButton = () => {
      if (confirmButton) {
        return (
          <confirmButton.type {...confirmButton.props} onClick={handleConfirm}>
            {confirmButton.props.children}
          </confirmButton.type>
        )
      }

      return <Button variant="primary" type="submit" onClick={handleConfirm} >Confirm</Button>
    }

    const renderCancelButton = () => {
      if (cancelButton) {
        return (
          <cancelButton.type {...cancelButton.props} onClick={handleCancel}>
            {cancelButton.props.children}
          </cancelButton.type>
        )
      }

      return <Button variant='outline-secondary' onClick={handleCancel}>Cancel</Button>
    }

    return (
      <Row className='justify-content-around'>
        <Col md={{ span: 3, offset: 0 }}>
          {renderCancelButton()}
        </Col>
        <Col md={{ span: 3, offset: 0 }}>
          {renderConfirmButton()}
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    return (
      <StyledContainer>
        <Row className='mb-4'>
          <Col>{content}</Col>
        </Row>
        <Row>
          <Col>{renderButtons()}</Col>
        </Row>
      </StyledContainer>
    )
  }

  const renderModal = () => (
    <StyledModal
      width={width}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      {renderContent()}
    </StyledModal>
  )

  const renderButton = () => {
    if (!button) {
      return (
        <>
          <Button onClick={() => setShowModal(true)} />
          {renderModal()}
        </>
      )
    }

    return (
      <>
        <button.type {...button.props} onClick={() => setShowModal(true)}>
          {button.props.children}
        </button.type>
        {renderModal()}
      </>
    )
  }

  /**
    * Render as modal
    */

  return renderButton()

}

export default Confirm

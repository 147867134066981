import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { routes } from "./utils/routes";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from 'react-redux';

function App() {
  const routing = useRoutes(routes);
  const { title } = useSelector((state) => state.config)
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title ? `${title} | CodelessNFT` : 'CodelessNFT'}</title>
        </Helmet>
      </HelmetProvider>
      <Suspense fallback={"loading..."}>{routing}</Suspense>
    </>
  );
}

export default App;

import React from "react";
import {
  ExportCollectionProgressBarMain,
  ExportStatusCollection
} from "./style";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const ExportCollectionProgressBar = ({
  progressShow,
  exportStatus,
}) => {
  const MIN = 0;
  const MAX = exportStatus?.finish;
  const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);
  return (
    <div>
      <ExportCollectionProgressBarMain progressShow={progressShow}>
        <Box sx={{ width: '100%', color: '#7453de' }}>
          {
            !exportStatus || exportStatus?.status === '-1'
              ? <LinearProgress color="inherit" />

              : <LinearProgress
                variant="determinate"
                color="inherit"
                value={normalise(exportStatus?.status ==='1' ? exportStatus?.finish : exportStatus?.current)}
              />
          }

        </Box>
        <ExportStatusCollection>{!!exportStatus ? exportStatus?.message : 'Export in progress...'}</ExportStatusCollection>

      </ExportCollectionProgressBarMain>
    </div>

  )
}

export default ExportCollectionProgressBar;

import styled from "styled-components";

export const DeleteModalContent = styled.div`

    p {
        font-family: "Roboto Regular";
        font-size: 14px;
        line-height: 18px;
        color: #cad6ec;
        margin-bottom: 0px;
    }
    
    img{
        float: left;
        margin-right: 10px;
    }

    h2{
        font-family: "Roboto Bold";
        font-size: 24px;
        line-height: 20px;
        color: #cad6ec;
        margin-bottom: 16px;
    }
    

`;

export const ButtonsBox = styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button{
        background: linear-gradient(90deg, #7b33eb 21.89%, rgba(1, 100, 216, 0.71) 98.16%) !important;
        border: none !important;
        width: 40% !important;
        font-family: "Roboto Bold";
        font-size: 20px !important;
        line-height: 20px !important;
        color: #cad6ec !important;
        padding: 18px 10px !important;
        text-transform: uppercase;
        border-radius: 6px;
    }
`;
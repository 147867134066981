import styled from "styled-components";

export const ExportCollectionProgressBarMain = styled.div`
    width: 55%;
    height: 60px;
    background: #1d1e22;
    position: fixed;
    bottom: 10px;
    left: 23%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px #5e5c5c7a;
    // opacity: ${props => props.progressShow ? '1' : '0'};;
    transition: .6s;
    z-index: 9999;
`;

export const ExportCollectionProgressBar = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ExportStatusCollection = styled.div`
    width: 100%;
    height: 20px;
    font-family: "Roboto Bold";
    font-size: 15px;
    line-height: 20px;
    color: #cad6ec;
    transition: .3s;
    display: flex;
    justify-content: center;
    align-items: center;
`;

import React from 'react';
import { Form } from 'react-bootstrap';

function CollectionDetails() {
    return (
        <>
            <div className='collectionDetails-main selectContacts-cont'>
                <h3>Provide basic details about your NFT collection.</h3>
                <div className='page-devider'></div>
                <div className='collectionDetails-cont'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <form className='collectionDetails-form'>
                                <label htmlFor="CollectionName">
                                    <label>COLLETION NAME <span>*</span><img src='/images/trait-icon.png' alt="" /></label>
                                    <input
                                        name="CollectionName"
                                        type="text"
                                        placeholder="Happy Clowns"
                                    />
                                </label>
                                <label htmlFor="TraitDescription">
                                    <label>COLLETION DESCRIPTION <span>*</span><img src='/images/trait-icon.png' alt="" /></label>
                                    <textarea
                                        name="TraitDescription"
                                        type="text"
                                        placeholder="A collection of 10,000 unique clowns ready to take the metaverse by storm."
                                    />
                                </label>
                                <label htmlFor="NFTname">
                                    <label>NFT NAME <span>*</span><img src='/images/trait-icon.png' alt="" /></label>
                                    <input
                                        name="NFTname"
                                        type="text"
                                        placeholder="Happy Clowns"
                                    />
                                </label>
                                <label htmlFor="CollectionWebsite">
                                    <label>COLLECTION WEBSITE <span>*</span><img src='/images/trait-icon.png' alt="" /></label>
                                    <input
                                        name="CollectionWebsite"
                                        type="text"
                                        placeholder="Eg. https://nftclowns.com"
                                    />
                                </label>
                                <div className='trait-switch'>
                                    <label>COLLECT ROYALTY <img src='/images/trait-icon.png' alt="" /></label>
                                    <div className="toggle-button-cover">
                                        <div className="button-cover">
                                            <div className="button b2" id="button-10">
                                                <input type="checkbox" className="checkbox" />
                                                <div className="knobs">
                                                    <span>Yes</span>
                                                </div>
                                                <div className="layer"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Form.Group className="mt-2 mb-3">
                                    <Form.Label> ROYALTY AMOUNT <span>*</span></Form.Label>
                                    <input type='number' placeholder='3.00%' className='royaltyAmount' />
                                </Form.Group>
                                <label htmlFor="royaltyWalletAddress">
                                    <label>ROYALTY WALLET ADDRESS <span>*</span><img src='/images/trait-icon.png' alt="" /></label>
                                    <input
                                        name="royaltyWalletAddress"
                                        type="text"
                                        placeholder="Eg. https://nftclowns.com"
                                    />
                                </label>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CollectionDetails
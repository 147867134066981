import styled from "styled-components";

export const TraitsLeftMenu = styled.div`
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #1F2328;
    flex: ${(p) => p.hasTraits ? 2 : 0}

    @media(max-height: 600px){
        overflow-y: auto;
    }
`;
export const TraitsLeftMenuTopSide = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;
export const TraitsLeftMenuButtons = styled.div`
    width: 90%;
    height: 35px;
    padding: 6px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // margin: 20px 5px;
    // background: #1D1E22;
    // border-radius: 24px;

`;
export const TraitsLeftMenuButton = styled.button`
    width: 76px;
    height: 25px;
    border: none;
    border-radius: 13px;
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: 900;
    font-size: 11px;
    line-height: 7px;
    background: ${props => props.defaultButton ? " linear-gradient(90deg, #7B33EB 21.89%, rgba(9, 133, 246, 0.71) 98.16%)" : "#1D1E22"};
    color: ${props => props.defaultButton ? "#1F2328" : "#CAD6EC"};
    }

    :focus {
        background: linear-gradient(90deg, #7B33EB 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
        color: #1F2328;
    }
    :active {
        background: linear-gradient(90deg, #7B33EB 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
    colo    r: #1F2328;
    }
`;
export const TraitsLeftMenuList = styled.ul`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 5px;

    h5{
        font-family: 'Roboto Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: #CAD6EC;
        margin:0;
    }
`;

export const TraitsLeftMenuListAddDiv = styled.div`
    width: 90%;
    height: 37px;
    border-radius: 5px;
    margin: 5px 0;
    font-family: 'Roboto Regular';
    font-style: normal;
    background: #1D1E22;
    opacity:${props => props.disabled ? 0.4 : 1 };


    form {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    button {
        padding: 1px 7px;
        font-size: 14px;
        border-radius: 3px;
        background: #1D1E22;
        border: 1px solid  #7B33EB;
        color: #CAD6EC;
        font-weight: bold;
        margin: 0 10px;
        font-family: 'Roboto Bold';
    }

    input {
        width: 70%;
        height: 30px;
        background: #1D1E22;
        border: none;
        outline: none;
        color: #CAD6EC;
    }
`;
export const TraitsLeftMenuBorderedButton = styled.div`
    list-style: none;
    box-sizing: border-box;
    width: 90%;
    height: 37px;
    cursor: pointer;
    background: rgba(157, 157, 157, 0.04);
    border: 1px solid #7B33EB;
    border-radius: 5px;
    margin: 5px 0;
     opacity:${props => props.disabled ? 0.4 : 1 };


    button {
        width: 100%;
        height: 100%;
        border: none;
        background:none;
        color: #CAD6EC;
        font-family: 'Roboto Bold';
        font-style: normal;
        font-weight: 900;
        font-size: 13px;
        line-height: 20px;

        img {
            margin-right: 8px;
        }
    }
`;

export const TraitsLeftMenuBackGroundButton = styled.div`
    list-style: none;
    box-sizing: border-box;
    width: 90%;
    height: 38px;
    cursor: pointer;
    background: linear-gradient(90deg, #7B33EB 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
    border-radius: 5px;
    margin: 5px 0;
    opacity:${props => props.disabled ? 0.5 : 1 };


    button {
        width: 100%;
        height: 100%;
        border: none;
        background:none;
        color: #1D1E22;
        font-family: 'Roboto Bold';
        font-style: normal;
        font-weight: 900;
        font-size: 13px;
        line-height: 20px;

        img {
            margin-right: 8px;
        }
    }
`;

export const TraitsLeftMenuLine = styled.span`
    width: 90%;
    height: 0.5px;
    background: #2E343A;
    margin: 20px 0;
`;
export const TraitsLeftMenuBottomSide = styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:10px 0;
`;

import React, { useEffect, useState } from 'react';
import HelpIcon from '../../../asset/icons/help-icon.svg';
import TraitSettingsIcon from '../../../asset/icons/trait-settings.svg';
import VariationSettingsIcon from '../../../asset/icons/file-settings.svg';
// import TraitInputsTags from '../../ReactTag';
import {
  TraitsRightMenu,
  TraitsRightMenuButton,
  TraitsRightMenuForm,
  TraitsRightMenuLabel,
  TraitsRightMenuSettings
} from './style';
import ConfirmDeleteModal from '../../confirmDeleteModal';
import * as actions from "../../../redux/action"
import { connect } from 'react-redux';


function RightMenu({
  traitsCard,
  setTraitsCard,
  collectionDataId,
  // createTraitTags,
  // deleteTraitTags,
  image,
  setImage,
  updateImageVariationName,
  updateTraitPosition,
  deleteData,
  disable,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalType, setDeleteModalType] = useState(false);
  const [itemIndex, setItemIndex] = useState('');
  const [options, setOpitions] = useState([]);
  const [traitName, setTraitName] = useState('');
  const [traitPosition, setTraitPosition] = useState('');

  useEffect(() => {
    if (traitsCard.cards && traitsCard.cards.length > 0) {
      const index = traitsCard?.cards?.findIndex(object => {
        return object.id === traitsCard.selectedTrait.id;
      })
      setItemIndex(
        index === 0 ? index + 1 : index - 1
      )
      setOpitions(traitsCard.cards.map((card) => ({
        id: card.id, value: card.position,
      })))
    } else setItemIndex('')
  }, [traitsCard])

  const handleVariationName = (e) => {
    setImage({
      ...image,
      variation: e.target.value,
    })
  }

  useEffect(() => {
    setTraitName(traitsCard.selectedTrait?.name);
    setTraitPosition(traitsCard.selectedTrait?.position)
  }, [traitsCard.selectedTrait])

  const updateTraitData = () => {
    updateTraitPosition({
      collectionID: collectionDataId,
      traitID: traitsCard.selectedTrait.id,
      traitPrevPosition: traitsCard.selectedTrait?.position,
      values: {
        name: traitName,
        position: traitPosition,
      }
    })
  }

  const addVariationName = () => {
    updateImageVariationName({
      collectionID: collectionDataId,
      traitID: traitsCard.selectedTrait.id,
      imageID: image?.id,
      values: {
        name: image?.variation,
      }
    })
  }

  return (
    <>
      <TraitsRightMenu>
        <TraitsRightMenuSettings>
          <h4><img src={TraitSettingsIcon} alt='' /> TRAIT SETTINGS</h4>
          <TraitsRightMenuForm onSubmit={(e) => e.preventDefault()}>
            <TraitsRightMenuLabel
              htmlFor='trait_order'
            >
              TRAIT ORDER
              <img src={HelpIcon} alt='' />
            </TraitsRightMenuLabel>
            <select
              value={traitPosition}
              onChange={(e) => setTraitPosition(e.target.value)}
              onBlur={updateTraitData}
              disabled={disable}
            >
              {
                options.map((item) =>
                  <option
                    key={item.id}
                    value={item.value}
                  >{item.value}</option>
                )

              }
            </select>
            <TraitsRightMenuLabel
              htmlFor='trait_name'
            >
              TRAIT NAME
              <img src={HelpIcon} alt='' />
            </TraitsRightMenuLabel>
            <input
              id='trait_name'
              type="text"
              value={traitName}
              onChange={(e) => {
                setTraitName(
                  e.target.value
                )
              }}
              onBlur={updateTraitData}
              disabled={disable}
            />
            {/* <TraitsRightMenuLabel htmlFor='trait_tag'>TRAIT TAG  <img src={HelpIcon} alt='' /></TraitsRightMenuLabel>
            <TraitInputsTags
              collectionID={collectionDataId}
              trait={traitsCard.selectedTrait}
              createTraitTags={createTraitTags}
              deleteTraitTags={deleteTraitTags}
              disable={disable}
            /> */}
            <TraitsRightMenuButton type='button' disabled={disable} onClick={() => {
              setShowDeleteModal(true);
              setDeleteModalType(true);
            }}>DELETE TRAIT</TraitsRightMenuButton>
          </TraitsRightMenuForm>
        </TraitsRightMenuSettings>
        {
          image.id ? <TraitsRightMenuSettings>
            <h4><img src={VariationSettingsIcon} alt='' /> VARIATION SETTINGS</h4>
            <TraitsRightMenuForm onSubmit={(e) => e.preventDefault()}>
              <TraitsRightMenuLabel htmlFor='variation-name'>VARIATION NAME  <img src={HelpIcon} alt='' /></TraitsRightMenuLabel>
              <input
                id='variation-name'
                type="text"
                value={image.variation}
                onChange={handleVariationName}
                onBlur={addVariationName}
                disabled={disable}

              />
              <TraitsRightMenuButton type='button' disabled={disable} onClick={() => {
                setShowDeleteModal(true);
                setDeleteModalType(false);
              }}>DELETE FILE</TraitsRightMenuButton>
            </TraitsRightMenuForm>
          </TraitsRightMenuSettings> : <></>
        }

      </TraitsRightMenu >
      <ConfirmDeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteModalType={deleteModalType}
        setTraitsCard={setTraitsCard}
        traitID={traitsCard.selectedTrait.id}
        itemIndex={itemIndex}
        collectionDataId={collectionDataId}
        deleteData={deleteData}
        imageId={image?.id}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  createTraitTags: (data, setter) => dispatch(actions.createTraitTags(data, setter)),
  deleteTraitTags: (data) => dispatch(actions.deleteTraitTags(data)),
  updateImageVariationName: (data) => dispatch(actions.updateImageVariationName(data)),
  updateTraitPosition: (data) => dispatch(actions.updateTraitPosition(data)),
})

export default connect(null, mapDispatchToProps)(RightMenu);

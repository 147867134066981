import React from 'react';
import DoughnutChart from './doughnutChart';
import MintsChart from './mintsChart';

const Reporting = () => (
  <div className="reporting-main">
    <MintsChart name="MINTS" />
    <MintsChart name="REVENUE" />
    <DoughnutChart name="TOTAL MINTED" />
  </div>
);

export default Reporting;

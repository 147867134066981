import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function Payment() {
    const [searchParams] = useSearchParams();
    useEffect(() => {
        let token = searchParams.get("approval_token_id")
        localStorage.setItem('__token', token)
        window.close();
    }, []);

    return (
        <>

        </>
    )
}

import React from 'react'

const BillingPlan = () => {


    return (
        <>
            <div className="billingPlan">BillingPlan</div>
        </>
    )
}

export default BillingPlan

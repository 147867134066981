import React, { useState } from 'react'
import { CanvasJSChart } from 'canvasjs-react-charts'
import DatePicker from 'react-datepicker';
import moment from 'moment';
const MintsChart = (props) => {
    const [selectedDate, setSelectedDate] = useState('')
    const mintsOption = {
        animationEnabled: true,
        backgroundColor: "rgb(247 248 251)",
        // title: {
        //     text: "Mints"
        // },
        axisX: {
            valueFormatString: "MMM DD",
            labelFontSize: 14,
            labelFontColor: "#A2A3A5",
            labelFontFamily: "Poppins Regular",
            lineColor: "transparent",
            crosshair: {
                enabled: true,
                snapToDataPoint: true
            }
        },
        axisY: {
            // title: "Sales (in USD)",
            // prefix: "$"
            labelFontSize: 14,
            lineColor: "transparent",
            gridColor: "#ECE9F1",
            labelFontColor: "#A2A3A5",
            labelFontFamily: "Poppins Regular",
        },
        data: [{
            yValueFormatString: "#,###",
            xValueFormatString: "MMM DD",
            color: "rgba(66, 47, 138, 0.87)",
            type: "spline",
            dataPoints: [
                { x: new Date("2018-05-13"), y: 10 },
                { x: new Date("2018-05-14"), y: 50 },
                { x: new Date("2018-05-15"), y: 30 },
                { x: new Date("2018-05-16"), y: 70 },
                { x: new Date("2018-05-17"), y: 50 },
                { x: new Date("2018-05-18"), y: 60 },
                { x: new Date("2018-05-19"), y: 50 },
            ]
        }]
    }
    const onDateChange = (date) => {
        const dateFormat = moment(date).format("MMMM YYYY")
        setSelectedDate(dateFormat)
    }
    return (
        <div className="mint-chart">
            <div className="mint-chart-cont">
                <h1>{props.name}</h1>
                <div className='mint-chart-datepicker-right'>
                    <input value={selectedDate} type='text' placeholder='March 2020' />
                    <DatePicker
                        customInput={<img src='/images/celender-icon.png' alt="" />}
                        onChange={(date) => onDateChange(date)}
                        showMonthYearPicker
                        showFullMonthYearPicker
                    />
                </div>
            </div>
            <div className="reporting-chart" >
                <div className='trail-hide'></div>
                <CanvasJSChart options={mintsOption} />
            </div>
        </div>
    )
}

export default MintsChart
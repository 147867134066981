import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as actions from "../../redux/action"
import { StyledCollectionInfoContainer } from './styles';
import Slider from 'react-rangeslider';

function Collection({
  addCollection,
  setAddCollection,
  createCollection,
  collectionEditData,
  editCollection,
  setCollectionEditData,
  getCollectionData,
  createTraitCardItem,
}) {
  const [editMode, setEditMode] = useState(false)
  const [errorMessage, setErrorMessage] = useReducer((state, payload) => {
    return {
      ...state,
      ...payload,
    }
  }, {
    name: null,
    description: null,
    url: null,
    image_x: null,
    image_y: null,
  })
  const navigate = useNavigate();

  const getInitialState = useCallback(() => {
    const { id, ...data } = collectionEditData;

    return id ? {
      ...data,
    } : {
      name: '',
      description: '',
      url: '',
      images: 100,
      image_x: '',
      image_y: '',
    }
  }, [collectionEditData])

  const [initialState, setInitialState] = useReducer((state, payload) => {
    return { ...state, ...payload }
  }, getInitialState());

  useEffect(() => {
    setInitialState(getInitialState())
    setEditMode(!!collectionEditData.id);
  }, [collectionEditData, getInitialState])


  const handleChange = (e) => {
    if (e >= 1000) {
      setInitialState({ images: Math.floor(e / 1000) * 1000 });
      return
    }
    setInitialState({ images: 100 });
  }

  const onSubmit = () => {
    if (editMode) {
      editCollection({ data: initialState, id: collectionEditData.id })
        .then(() => {
          setCollectionEditData({});
          setAddCollection(!addCollection);
          setErrorMessage({});
        }).catch((error) => {
          setErrorMessage(
            error.response.data
          )
        });
    } else {
      delete initialState.logo
      createCollection(initialState)
        .then((response) => {
          setCollectionEditData({});
          setAddCollection(!addCollection);
          setErrorMessage({});
          if (response.status === 200) {
            createTraitCardItem({
              id: response.data.collection.data.id,
              values: {
                name: 'Background',
                position: 1,
              }
            });
            navigate(`/nft/traits/${response.data.collection.data.id}`);
          } else getCollectionData();
        }).catch((error) => {
          setErrorMessage(
            error.response.data
          )
        });
    }
  }

  return (
    <>
      <Modal
        show={addCollection}
        onHide={() => {
          setAddCollection(!addCollection)
          setCollectionEditData({})
        }}
        className='new-collection-modal-main'
      >
        <Modal.Body>
          <div className='new-collection-modal-content'>
            {editMode ? <h2>Edit Collection</h2> : <h2>Create a New Collection </h2>}
            <p>Give your collection a unique name to identify it.</p>
            <div className='modal-devider'></div>
            <Form onSubmit={onSubmit}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <StyledCollectionInfoContainer>
                      <div>
                        <Field name={`collectionName`}>
                          {({ input }) => (
                            <label htmlFor="collectionName">
                              <label>COLLECTION NAME</label>
                              <input
                                className={`collectionInputs ${errorMessage.name ? 'errorMessageInput' : ''}`}
                                {...input}
                                id="collectionName"
                                name="collectionName"
                                placeholder="Eg.  Happy Clowns"
                                value={initialState.name}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setInitialState({ name: e.target.value })
                                  setErrorMessage({ name: null })
                                }}
                              />
                            </label>
                          )}
                        </Field>
                        <Field name={`collectionDescription`}>
                          {({ input }) => (
                            <label htmlFor="CollectionDescription">
                              <label>COLLECTION DESCRIPTION</label>
                              <textarea
                                {...input}
                                className={`collectionInputs ${errorMessage.description ? 'errorMessageInput' : ''}`}
                                name="collectionDescription"
                                placeholder="Eg.  A collection of friendly clowns..."
                                value={initialState.description}
                                onChange={(e) => {
                                  setInitialState({ description: e.target.value });
                                  setErrorMessage({ description: null })
                                }}
                              />
                            </label>
                          )}
                        </Field>
                        {/* <Field name={`collectionName`}>
                          {({ input }) => (
                            <label htmlFor="collectionName">
                              <label>COLLECTION URL</label>
                              <input
                                className={`collectionInputs ${errorMessage.url ? 'errorMessageInput' : ''}`}
                                {...input}
                                id="collectionUrl"
                                name="collectionUrl"
                                placeholder="Eg.  url"
                                value={initialState.url}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setInitialState({ url: e.target.value })
                                  setErrorMessage({ url: null })
                                }}
                              />
                            </label>
                          )}
                        </Field> */}
                      </div>

                    </StyledCollectionInfoContainer>

                    <div className='form-group'>
                      <label>UNIQUE IMAGES IN COLLECTION</label>
                      <Slider
                        min={100}
                        max={10000}
                        value={initialState.images}
                        onChange={handleChange}
                        handleLabel={initialState.images.toString()}

                      />
                    </div>

                    <label htmlFor="ImageDemension" style={{ marginTop: "5px" }}>
                      <label>IMAGE DIMENSIONS</label>
                      <div className='ImageDemension'>
                        <Field name={`imageDimensionX`}>
                          {({ input }) => (
                            <input
                              {...input}
                              className={`collectionInputs ${errorMessage.image_x ? 'errorMessageInput' : ''}`}
                              name="imageDimensionX"
                              type="text"
                              placeholder="4500 px"
                              value={initialState.image_x}
                              onChange={(e) => {
                                setInitialState({ image_x: e.target.value });
                                setErrorMessage({ image_x: null })
                              }}
                            />
                          )}
                        </Field>
                        <span>X</span>
                        <Field name={`imageDimensionY`}>
                          {({ input }) => (
                            <input
                              {...input}
                              className={`collectionInputs ${errorMessage.image_y ? 'errorMessageInput' : ''}`}
                              name="imageDimensionY"
                              type="text"
                              placeholder="4500 px"
                              value={initialState.image_y}
                              onChange={(e) => {
                                setInitialState({ image_y: e.target.value });
                                setErrorMessage({ image_y: null })
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </label>
                    <input type="submit" className='btn login-btn' value={editMode ? "SAVE" : "CREATE"} />
                  </form>
                )
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  createCollection: (values) => dispatch(actions.createCollection(values)),
  editCollection: (values) => dispatch(actions.editCollection(values)),
  getCollectionData: () => dispatch(actions.getCollectionData()),
  createTraitCardItem: (data) => dispatch(actions.createTraitCardItem(data)),
})

export default connect(null, mapDispatchToProps)(Collection)

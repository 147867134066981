import React from 'react'
import Pagination from 'react-paginate'
import ArrowRight from '../../asset/icons/arrow-right.svg';
import ArrowLeft from '../../asset/icons/arrow-left.svg';
import PropTypes from 'prop-types'
import './styles.css'

const ReactPagination = ({
  totalCount,
  forcePage,
  currentPage,
  pageCount,
  onPageChange,
  ...props
}) => (
  <Pagination
    totalCount={totalCount || 1}
    pageRangeDisplayed={5}
    forcePage={forcePage}
    nextLabel={<img src={ArrowRight} alt="" />}
    previousLabel={<img src={ArrowLeft} alt="" />}
    pageCount={pageCount || 1}
    siblingCount={3}
    breakLabel="..."
    breakClassName={props.breakClassName || 'page-item'}
    breakLinkClassName={props.breakLinkClassName || 'page-link'}
    containerClassName={props.containerClassName || 'pagination'}
    pageClassName={props.pageClassName || 'page-item'}
    pageLinkClassName={props.pageLinkClassName || 'page-link'}
    previousClassName={props.previousClassName || 'page-item'}
    previousLinkClassName={props.previousLinkClassName || 'page-link'}
    nextClassName={props.nextClassName || 'page-item'}
    nextLinkClassName={props.nextLinkClassName || 'page-link'}
    activeClassName={props.activeClassName || 'active'}
    onPageChange={onPageChange}
  />
)

ReactPagination.propTypes = {
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func,
  breakClassName: PropTypes.string,
  breakLinkClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  pageClassName: PropTypes.string,
  pageLinkClassName: PropTypes.string,
  previousClassName: PropTypes.string,
  previousLinkClassName: PropTypes.string,
  nextClassName: PropTypes.string,
  nextLinkClassName: PropTypes.string,
  activeClassName: PropTypes.string
}

ReactPagination.defaultProps = {
  totalCount: 1,
  currentPage: 1,
  pageCount: 1,
  onPageChange: () => { },
}

export default ReactPagination

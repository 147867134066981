import styled from "styled-components";

export const StyledPaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledAddNewCollectionBox = styled.div`
  width: 200px;
  border-radius: 6px;
  position: absolute;
  top:0;
  right:30px;
`
export const StyledDashboardImageCollection = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => !!props.logo ? props.logo : null});
  background-position: center;
  background-size:${props => !!props.sizeLogo ? "contain" : "auto"};
  background-repeat: no-repeat;
`
export const StyledDashboardImageUpload = styled.button`
  width: 250px;
  height: 55px;
  border: none;
  background: linear-gradient(90deg,#7B33EB 21.89%,rgba(9,133,246,0.71) 98.16%);
  color: white;
  border-radius: 5px;
  isplay: flex;
  align-items: center;
  justify-content: center;

  :hover{
    background: linear-gradient(90deg,#7b33ebb5 21.89%,rgb(9 133 246 / 52%)  98.16%);
  }

  img{
    width: 17px;
    margin-right: 11px;
  }

  .btn-check:focus+.btn, .btn:focus{
    outline: none;
    box-shadow: none;
  }
  span{
    font-size: 16px;
    padding: 0;
    font-family: 'Roboto Regular';

  }
`

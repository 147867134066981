import React, { useState } from 'react';
import { Dropdown, Tab, Tabs } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import TimeZone from "../../utils/timeZone";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { FaQuestionCircle } from "react-icons/fa";

function MentingOptions() {
  const [timeZoneName, setTimeZoneName] = useState("")
  const [revealPercent, setRevealPercent] = useState('')
  const [minting, setMinting] = useState({
    lazyMinting: false,
    normalMinting: false
  })
  console.log("minitng", minting)
  const [selectedTimezone] = useState('Pacific/Midway')
  console.log("selectedtimezone", selectedTimezone)
  const [revealDate, setRevealDate] = useState({
    revealByPercent: false,
    revealByDate: false
  })
  const [dateSelect, setDateSelect] = useState({
    imidiate: false,
    specifyDate: false
  });
  const [cDate, setCDate] = useState('')
  const [cRevealDate, setCRevealDate] = useState('')
  const getCurrentTime = () => {
    const timezoneoffset = moment().format("DD/MM/YYYY,HH:mm")
    // const currentDate = new Date().toTimeString()
    const momentOffset = moment().format("Z")
    const timeZone = TimeZone;
    const zoneName = timeZone.find((item) => item?.offset === `GMT${momentOffset}`)
    setTimeZoneName(zoneName?.name)
    setCDate(timezoneoffset)
    setDateSelect({ imidiate: true })
  }
  const onDateChange = (date) => {
    console.log("...Date....", date)
    const dateformat = moment(date).format("DD/MM/YYYY, HH:mm")
    setCDate(dateformat)
  }
  const onRevealDateChange = (date) => {
    const dateFormat = moment(date).format("DD/MM/YYYY, HH:mm")
    setCRevealDate(dateFormat)
  }
  const onTimeZoneChange = (e) => {
    console.log("timezone selected")
    setTimeZoneName(e.target.value)
  }

  return (
    <>
      <div className='collectionDetails-main selectContacts-cont'>
        <h3>Provide basic details about your NFT collection.</h3>
        <div className='page-devider'></div>
        <div className='collectionDetails-cont'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <form className='collectionDetails-form'>
                <div className='row '>
                  <div className='col-lg-6 col-md-6 col-sm-12 '>
                    {/* <label htmlFor="ContactSymbol">
                                            <label>CONTRACT SYMBOL <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                            <input
                                                name="ContactSymbol"
                                                type="text"
                                                placeholder=""
                                            />
                                        </label> */}
                    <div className='options-forms'>
                      <label>PRICING OPTIONS <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>

                      <Tabs defaultActiveKey="basic" id="uncontrolled-tab-example" className="options-forms-lists-main mb-3">
                        <Tab eventKey="basic" title="Basic" className="">
                          <div className='row '>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                              <label htmlFor="MintPrice">
                                <label>MINT PRICE <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                <input
                                  name="MintPrice"
                                  type="text"
                                  placeholder="Eg. .05 ETH  (~$350.00 USD)"
                                />
                              </label>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="advanced" title="Advanced" className="">
                          <div className='col-12 mint-price-mutiple p-0'>
                            <div className='row'>
                              <div className='col-lg-4 col-md-6 col-sm-12'>
                                <label htmlFor="MintPrice">
                                  <label>MINT PRICE <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                  <input
                                    name="MintPrice"
                                    type="text"
                                    placeholder=".05 ETH (~$350.00 USD)"
                                  // value=".05 ETH (~$350.00 USD)"
                                  />
                                </label>
                              </div>
                              <div className='col-lg-4 col-md-6 col-sm-12 mint-nft-mutiple'>
                                <label>NFT # <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                <div className='mint-nft-fields'>
                                  <label htmlFor="MintPrice">
                                    <input
                                      name="MintPrice"
                                      type="text"
                                      placeholder="1"
                                    // value="1"
                                    />
                                  </label>
                                  <span className='mint-nft-dased'>—</span>
                                  <label htmlFor="MintPrice">
                                    <input
                                      name="MintPrice"
                                      type="text"
                                      placeholder="1000"
                                    // value="1000"
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className='col-lg-4 col-md-6 col-sm-12 mint-nft-whitelist'>
                                <label htmlFor="MintPrice">
                                  <label></label>
                                  <input
                                    name="MintPrice"
                                    type="text"
                                    placeholder="Whitelist (25)"
                                  // value="Whitelist (25)"
                                  />
                                  <img src='/images/edit-project.png' alt="" />

                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 mint-price-mutiple p-0'>
                            <div className='row'>
                              <div className='col-lg-4 col-md-6 col-sm-12'>
                                <label htmlFor="MintPrice">
                                  <label>MINT PRICE <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                  <input
                                    name="MintPrice"
                                    type="text"
                                    placeholder=".05 ETH (~$350.00 USD)"
                                  />
                                </label>
                              </div>
                              <div className='col-lg-4 col-md-6 col-sm-12 mint-nft-mutiple'>
                                <label>NFT # <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                <div className='mint-nft-fields'>
                                  <label htmlFor="MintPrice">
                                    <input
                                      name="MintPrice"
                                      type="text"
                                      placeholder="1"
                                    />
                                  </label>
                                  <span className='mint-nft-dased'>—</span>
                                  <label htmlFor="MintPrice">
                                    <input
                                      name="MintPrice"
                                      type="text"
                                      placeholder="1000"
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className='col-lg-4 col-md-6 col-sm-12 mint-nft-whitelist'>
                                <label htmlFor="MintPrice">
                                  <label></label>
                                  <input
                                    name="MintPrice"
                                    type="text"
                                    placeholder="Whitelist (25)"
                                  />
                                  <img src='/images/edit-project.png' alt="" />

                                </label>
                              </div>
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>

                <div className='col-12 p-0'>
                  <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                    <label htmlFor="MaxPerWallet">
                      <label>MAX PER WALLET <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                      <input
                        name="MaxPerWallet"
                        type="text"
                        placeholder="Eg. 5"
                      />
                    </label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <label>MINT DATE <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12 date-input'>

                    {/* <label htmlFor="Immediately">
                                            <input
                                                name="Immediately"
                                                type="text"
                                                placeholder="Immediately"
                                            />
                                            <img src="/images/celender-icon.png" alt="" />
                                        </label> */}
                    {/* {dateSelect?.imidiate && (
                                           <>
                                                <label htmlFor="Immediately">
                                                    <input
                                                        name="Immediately"
                                                        type="text"
                                                        placeholder="Immediately"
                                                        value={cDate === null ? "" : `${cDate}, (${timeZoneName})`}
                                                    />
                                                </label>
                                                <img src="/images/celender-icon.png" alt="" />
                                            </>

                                        )}  */}
                    <Dropdown>
                      <Dropdown.Toggle >
                        <>
                          <label htmlFor="Immediately">
                            <input
                              name="Immediately"
                              type="text"
                              autocomplete="off"
                              placeholder="Immediately"
                              value={cDate && `${cDate}, ${timeZoneName}`}
                            />
                          </label>
                          {/* <DatePicker
                                                        open={dateSelect?.specifyDate}
                                                        // selected={cDate}
                                                        onChange={(date) => {
                                                            onDateChange(date);
                                                        }}
                                                        customInput={<img src='/images/celender-icon.png' alt="" />}
                                                        showTimeSelect={true}
                                                    /> */}
                          <DatePicker
                            renderCustomHeader={() => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <select value={timeZoneName} onChange={(e) => onTimeZoneChange(e)}>
                                  {TimeZone.map((item) => (
                                    <option value={item?.name}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                            onChange={(date) => onDateChange(date)}
                            showTimeSelect={true}
                            customInput={<img src='/images/celender-icon.png' alt="" />}
                            open={dateSelect?.specifyDate}

                          />
                        </>


                      </Dropdown.Toggle>
                      {/* {dateSelect?.specifyDate === true && (
                                                <TimezoneSelect
                                                    value={timeZoneName}
                                                    onChange={(data) => { setTimeZoneName(data.label) }}
                                                />
                                            )} */}


                      <Dropdown.Menu>
                        <Dropdown.Item onClick={(e) => getCurrentTime(e)}>Immediately</Dropdown.Item>
                        <Dropdown.Item onClick={(e) => setDateSelect({ specifyDate: true })}>Specific Date/Time</Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>

                  </div>

                  {/* <div className='col-lg-6 col-md-6 col-sm-12 date-input'>
                                        <label htmlFor="EST">
                                            <input
                                                name="EST"
                                                type="text"
                                                placeholder="5/05/22, 10:00 EST (New York)"
                                            />
                                            <img src="/images/celender-icon.png" alt="" />
                                        </label>
                                    </div> */}
                </div>

                <div className='options-forms'>
                  <label>REVEAL OPTIONS <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                  <div className='options-forms-lists-main'>
                    <div className={minting.lazyMinting ? 'options-forms-lists active' : 'options-forms-lists'} onClick={() => setMinting({ lazyMinting: true })}>
                      <h5>Lazy Minting</h5>
                      {/* <span>(Instant Reveal)</span> */}
                    </div>
                    <div className={minting.normalMinting ? 'options-forms-lists active' : 'options-forms-lists'} onClick={() => setMinting({ normalMinting: true })} >
                      <h5>Normal Minting</h5>
                      {/* <span>(Reveal Later)</span> */}
                    </div>
                  </div>
                </div>
                <div className='options-forms'>
                  <label>REVEAL DATE <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                  <div className='options-forms-lists-main'>
                    <div className={!revealDate?.revealByPercent === true ? 'options-forms-lists ' : 'options-forms-lists active'}
                      onClick={() => setRevealDate({ revealByPercent: true })}>
                      <h5>After % Minted</h5>
                    </div>
                    <div className={!revealDate?.revealByDate === true ? 'options-forms-lists ' : 'options-forms-lists active'}
                      onClick={() => setRevealDate({ revealByDate: true })}>
                      <h5>By Date</h5>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12 p-0 '>
                  <div className='row'>
                    {revealDate?.revealByPercent ?
                      <div className='col-lg-6 col-md-6 col-sm-12 '>
                        <label htmlFor="EG">
                          <label>REVEAL PERCENTAGE <span>*</span></label>
                          <input
                            name="EG"
                            type="text"
                            placeholder="Eg. 100%"
                            value={revealPercent}
                            onChange={(e) => setRevealPercent(e.target.value)}
                          />
                        </label>
                      </div>
                      : revealDate?.revealByDate ?
                        <div className='col-lg-6 col-md-6 col-sm-12 date-input'>
                          <label htmlFor="EST">
                            <label>REVEAL DATE <span>*</span></label>
                            <input
                              name="EST"
                              // type="text"
                              placeholder="5/05/22"
                              value={cRevealDate && `${cRevealDate}, ${timeZoneName}`}
                            />
                            {/* <DatePicker
                                                            onChange={(date) => {
                                                                onRevealDateChange(date);
                                                            }}
                                                            customInput={<img src='/images/celender-icon.png' alt="" />} /> */}
                            {/* <img src="/images/celender-icon.png" alt="" /> */}
                            <DatePicker
                              renderCustomHeader={() => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <select value={timeZoneName} onChange={(e) => onTimeZoneChange(e)}>
                                    {TimeZone.map((item) => (
                                      <option value={item?.name}>
                                        {item?.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                              onChange={(date) => onRevealDateChange(date)}
                              showTimeSelect={true}
                              customInput={<img src='/images/celender-icon.png' alt="" />}
                            // open={dateSelect?.specifyDate}

                            />
                          </label>
                        </div>
                        : ""
                    }


                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MentingOptions


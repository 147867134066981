import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteCharacterCollection } from '../../redux/action';

function TraitDeleteModal({ traitDelete, setTraitDelete, traitId }) {
    const dispatch = useDispatch()

    const deleteTraitData = () => {
        dispatch(deleteCharacterCollection(traitId))
        setTraitDelete(!traitDelete)
    }
    return (
        <>
            <Modal className='delete-modal-main' show={traitDelete} onHide={() => { setTraitDelete(!traitDelete) }}>
                <Modal.Body>
                    <div className='delete-modal-content'>
                        <h2>Confirm Deletion </h2>
                        <p><img alt='' src="/images/confirm-mdl-icon.png" /> Are you sure you would like to delete this type? </p>
                        <button className='btn-delete' onClick={() => deleteTraitData()} >DELETE</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TraitDeleteModal

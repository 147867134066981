import { smartContractsType } from "../../constants/smartContracts"

export const smartContracts = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: smartContractsType.SHOW_NEXT_SMART_CONTRACT,
            })
        }
        catch (err) {
        }
    }
}

export const prevSmartContract = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: smartContractsType.SHOW_PREVIOUS_SMART_CONTRACT,
            })
        }
        catch (err) {
        }
    }
}

export const showManageContract = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: smartContractsType.SHOW_MANAGE_CONTRACT
            })
        } catch {

        }
    }
}
export const showpublishCollection = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: smartContractsType.SHOW_PUBLISHCOLLECTION
            })
        } catch (err) {

        }
    }
}
export const showMarketingKit = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: smartContractsType.SHOW_MARKETINGKIT
            })
        } catch {

        }
    }
}
export const showReporting = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: smartContractsType.SHOW_REPORTING
            })
        } catch {

        }
    }
}
export const showAdvanced = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: smartContractsType.SHOW_ADVANCED
            })
        } catch { }
    }
}
export const showBasic = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: smartContractsType.SHOW_BASIC
            })
        } catch { }
    }
}
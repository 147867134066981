import { smartContractsType } from "../../constants/smartContracts";

const initialState = {
    smartContracts: [],
    step: 0,
    manageContract: false,
    publishCollection: false,
    marketingKit: false,
    reporting: false,
    showAdvanced: false,
    showBasic: true,
};

const smartContractsReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case smartContractsType.SHOW_NEXT_SMART_CONTRACT:
            return {
                ...state,
                step: state.step + 1,
            }

        case smartContractsType.SHOW_PREVIOUS_SMART_CONTRACT:
            return {
                ...state,
                step: state.step - 1,
            }
        case smartContractsType.SHOW_MANAGE_CONTRACT:
            return {
                ...state,
                manageContract: true,
                publishCollection: false,
                marketingKit: false,
                reporting: false
            }
        case smartContractsType.SHOW_PUBLISHCOLLECTION:
            return {
                ...state,
                manageContract: false,
                publishCollection: true,
                marketingKit: false,
                reporting: false
            }
        case smartContractsType.SHOW_MARKETINGKIT:
            return {
                ...state,
                manageContract: false,
                publishCollection: false,
                marketingKit: true,
                reporting: false
            }
        case smartContractsType.SHOW_REPORTING:
            return {
                ...state,
                manageContract: false,
                publishCollection: false,
                marketingKit: false,
                reporting: true
            }
        case smartContractsType.SHOW_ADVANCED:
            return {
                ...state,
                showAdvanced: true,
                showBasic: false
            }
        case smartContractsType.SHOW_BASIC:
            return {
                ...state,
                showAdvanced: false,
                showBasic: true
            }
        default:
            return state
    }
}

export default smartContractsReducer
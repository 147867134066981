import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

export default function Select({ traitsCard, getOpition}) {
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <NativeSelect
                    defaultValue={traitsCard?.selectedTrait?.id}
                    inputProps={{
                        name: 'trait',
                        id: 'uncontrolled-native',
                    }}
                    onChange={(e)=>{
                        getOpition(e.target.value)
                    }}
                >
                    {traitsCard?.cards?.map((item, index) => (
                        <option
                            defaultValue={traitsCard?.selectedTrait.id}
                            key={index}
                            value={item.id}
                        >{item.name}</option>

                    ))}
                </NativeSelect>
            </FormControl>
        </Box>
    );
}
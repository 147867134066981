import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../redux/action'
import { CollectionExportButton } from './style';

const ExportBarButton = ({
  uniqueImages,
  checked,
  disable,
  handleConfirmExport,
  handleSubmit,
  setConfirmShowModal,
  confirmShowModal,
  collectionArchive,
  setPreviewCollectionShow,
  collectionId,
}) => {

  const handelClick = (e) => {
    if (!checked)
      return

    if (uniqueImages === 100) {
      handleConfirmExport(collectionId);
      setConfirmShowModal(!confirmShowModal);
      return
    }

    handleSubmit(e);
  }

  const handelPrevExport = () => {
    if (!!collectionArchive) {
      window.open(collectionArchive, "_self")
      return
    }

    setPreviewCollectionShow(false);
    setConfirmShowModal(true);
  }

  return (
    <>
      {
        checked !== undefined
          ? <CollectionExportButton
            disable={disable || !checked}
            onClick={!disable ? handelClick : null}
          >
            {`${uniqueImages === 100 ? "" : "PURCHASE +"} EXPORT`}
          </CollectionExportButton>
          :
          <CollectionExportButton
            disable={disable}
            onClick={!disable ? () => handelPrevExport() : null}
          >
            EXPORT COLLECTION
          </CollectionExportButton>
      }



    </>
  )
}

const mapStateToProps = (state) => ({
  lazyLoadDataEmpty: state?.collectionReducer?.isDataEmpty,
  exportData: state?.collectionReducer?.exportData,
  showProgress: state?.collectionReducer?.showProgress,
})

const mapDispatchToProps = (dispatch) => ({
  createTraitCardItem: (data) => dispatch(actions.createTraitCardItem(data)),
  dragAndDropTraitCard: (data) => dispatch(actions.dragAndDropTraitCard(data)),
  changeSelectedItem: (values) => dispatch(actions.changeSelectedItem(values)),
  saveAsDraft: (data) => dispatch(actions.saveAsDraft(data)),
  getPreviewCollection: (id) => dispatch(actions.getPreviewCollection(id)),
  exportCollection: (id) => dispatch(actions.exportCollection(id)),
  exportCollectionStatus: (id) => dispatch(actions.exportCollectionStatus(id)),
  getPreviewCollectionImages: (data) => dispatch(actions.getPreviewCollectionImages(data)),
  changeTimeoutId: (values) => dispatch(actions.changeTimeoutId(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExportBarButton)

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import CharacterModal from '../createCharacterModal';
import TraitDeleteModal from '../traitTypeDeleteModal';
import LeftMenu from './traitsBasicLeftMenu';
import RightMenu from './traitsBasicRightMenu';
import * as actions from "../../redux/action";
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';



function TraitsBasic({
  traits,
  getTraitCard,
  createImageCollection,
  getCollectionData,
  collectionData,
  deleteImageCollection,
  getCharacterList,
  notValidImages,
  setTitle,
}) {
  const [showCharacterModal, setShowCharacterModal] = useState(false)
  const [traitDelete, setTraitDelete] = useState(false);
  const advanceState = useSelector((state) => state?.smartContractsReducer?.showAdvanced)
  // const basicState = useSelector((state) => state?.smartContractsReducer?.showBasic)
  // const characterState = useSelector((state) => state?.collectionReducer?.characterData)
  const [disable, setDisable] = useState(false);

  const [traitsCard, setTraitsCard] = useState({
    cards: [],
    selectedTrait: {
      id: null,
    },
  });
  const [loader, setLoader] = useState(false)
  // const [defaultButton, setDefaultButton] = useState(true)
  const [image, setImage] = useState({
    id: null,
  })

  let { id } = useParams();

  useEffect(() => {
    getCharacterList();
    getCollectionData();
    setTitle('Traits');
  }, [])

  useEffect(() => {
    if (id) {
      getTraitCard({ id, traitID: '' });
    }
  }, [id])

  useEffect(() => {
    setTraitsCard(traits);
  }, [traits])


  const imageHandler = (arr) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setLoader(true);
        for (let i = 0; i < arr.length; i++) {
          (function (ind) {
            setTimeout(function () {
              createImageCollection({
                dataId: {
                  traitID: traitsCard?.selectedTrait?.id,
                  collectionID: id
                }, values: arr[ind],
              })
              setLoader(false);
            }, 1000 * (ind + 1));
          })(i);
        }
      }
    }

    reader.readAsDataURL(arr[0]);
    return true;
  };

  const deleteData = (imageId) => {
    deleteImageCollection({
      traitID: traitsCard?.selectedTrait?.id,
      collectionID: id,
      imageID: imageId,
    }).then(() => {
      getTraitCard({ id, traitID: traitsCard?.selectedTrait?.id });
      setImage({
        id: null
      });
    });
  }

  return (
    <>
      <div className='TraitsBasic-main-div'>
        <div className="content-main">
          <div className="left-menu-main">
            <LeftMenu
              hasTraits={!!traitsCard?.cards?.length}
              traitsCard={traitsCard}
              setTraitsCard={setTraitsCard}
              traitSelectedItem={traitsCard.selectedTrait}
              collectionData={collectionData?.data?.find(item => item.id === id)}
              setImage={setImage}
              id={id}
              disable={disable}
              setDisable={setDisable}
            // defaultButton={defaultButton}
            // setDefaultButton={setDefaultButton}
            />
          </div>
          {traitsCard?.cards?.length ? (
            <div className="archive-tabs-main">
              {!advanceState && (
                <div className='archive-tabs-button-right text-right'>
                  <button className='btn-watch'>Watch Tutorial <img src='/images/watch-icon.png' alt='' /></button>
                </div>
              )}
              {/* {
                !defaultButton ? <div className='advanced-tabs-content'>
                  <Advanced />
                </div> : <></>
              } */}


              <div className="traitsBasic-main">
                <form className="traitsBasic-upload"
                  onDrop={(e) => { imageHandler(e.dataTransfer.files) }}
                >
                  <div className="upload-btn-wrapper">
                    <label className="btn upload-btn" htmlFor="inputFileUpload">
                      <img src="/images/upload-icon.png" alt="" className='' />
                      <span>Drop your images here or <a>browse</a></span>
                      <p>(Supported file types: JPG, PNG, GIF - Max size: 10mb)</p>
                    </label>
                    <input type="file"
                      id="inputFileUpload"
                      onChange={(e) => imageHandler(e.target.files)}
                      accept="image/x-png,image/gif,image/jpeg"
                      multiple
                    />
                  </div>
                </form>
                <div className='collection-image-box'>
                  {traits.selectedTrait.images?.length
                    ? traits.selectedTrait.images
                      .map((item) => (
                        <div
                          className={image?.id === item?.id
                            ? 'traitsBasic-upload-list-cont active-image' : 'traitsBasic-upload-list-cont '}
                          key={item?.id} onClick={() => setImage(item)}
                        >
                          <img className='traitbasic-image' src={item?.link} alt="" />
                          <div className='traitsBasic-list-aper'>
                            <button onClick={() => deleteData(item?.id)} disabled={disable}> <img src="/images/remove.png" alt="" /> </button>
                            <span>{item?.rarity}%</span>
                          </div>
                          <div className='traitsBasic-upload-list-name'>{item.file_name}</div>
                        </div>
                      )) : null}
                  {!!notValidImages.length && notValidImages?.map((item, index) =>
                    <>
                      <div className='image-dimension-notCorrect' key={index}>
                        <div className='image-dimension-notCorrect-message'>{item.message}</div>
                        <div className='traitsBasic-upload-list-name'>{item.file_name}</div>
                      </div>
                    </>
                  )}
                  {
                    loader ? <div className='lodader-body'>
                      <Box className='loader-main'>
                        <CircularProgress />
                      </Box>
                    </div> : <></>
                  }
                </div>
              </div>


            </div>
          ) : null}
          <div className="right-menu-main">
            {traitsCard?.cards?.length ? (
              <RightMenu
                traitsCard={traits}
                setTraitsCard={setTraitsCard}
                collectionDataId={id}
                image={image}
                setImage={setImage}
                deleteData={deleteData}
                disable={disable}
              />
            ) : null}
          </div>
        </div>
        <CharacterModal
          setShowCharacterModal={setShowCharacterModal}
          showCharacterModal={showCharacterModal}
        />
        <TraitDeleteModal
          setTraitDelete={setTraitDelete}
          traitDelete={traitDelete}
          traitId={traitsCard?.selectedTrait?.id}
        />
      </div>
    </>

  )
}

const mapStateToProps = (state) => ({
  traits: state?.collectionReducer?.traits,
  collectionData: state?.collectionReducer?.collectionData,
  notValidImages: state?.collectionReducer?.notValidImages,
})

const mapDispatchToProps = (dispatch) => ({
  getTraitCard: (data) => dispatch(actions.getTraitCard(data)),
  createImageCollection: (values) => dispatch(actions.createImageCollection(values)),
  changeSelectedItemFromLocal: (data) => dispatch(actions.changeSelectedItem(data)),
  getCollectionData: () => dispatch(actions.getCollectionData()),
  deleteImageCollection: (data) => dispatch(actions.deleteImageCollection(data)),
  getCharacterList: () => dispatch(actions.getCharacterList()),
  setTitle: (title) => dispatch(actions.setTitle(title)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TraitsBasic)

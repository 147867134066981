import { Notyf } from "notyf";
import { callApi } from "../../../services/api";
import { loginType } from "../../constants/auth";
import { collectionDataType } from "../../constants/collections";

const notyf = new Notyf();

export const login = (response) => {
  return async () => {
    if (response.token && response.expires_in) {
      localStorage.setItem('token', response.token)
    }
    return true;
  }
}

export const getUserData = () => {
  return async (dispatch) => {
    callApi.get('user/view-profile').then((response) => {
      localStorage.setItem('myAccountData', JSON.stringify(response.data.data));
      dispatch({
        type: loginType.USER_DATA,
        payload: {
          user_data: response.data.data,
          products_praic: response.data.products,
        }
      })
    });
  }
}

/**
 * @deprecated - in future need to re-check if we really need that method?
 * @param values
 * @return {Function}
 */
export const signUp = (values) => {
  return async (dispatch) => {
    const userData = JSON.parse(localStorage.getItem("userData"))
    if (!userData) {
      localStorage.setItem("userData", JSON.stringify([values]))
    }
    if (userData) {
      const userExist = userData.find(item => item.email === values.email)
      if (!userExist) {
        localStorage.setItem("userData", JSON.stringify([...userData, values]))
      } else {
        alert("user already exists")
      }
      dispatch({
        type: collectionDataType.USER_DATA_TYPE,
        payload: values,
      })
    }
  }
}


export const signOut = () => {
  const dispatch = async (dispatch) => {
    window.localStorage.clear();
    dispatch({
      type: loginType.LOGOUT_SUCCESS,
    });
  };
  return dispatch;
};

export const updateUserData = (values) => {
  return async (dispatch) => {
    dispatch({
      type: loginType.UPDATE_USER_DATA,
      payload: values,
    },
    )
  }
}

export const promoCodeApply = (data) => {
  return async (dispatch) => {
    callApi.post(`collections/${data.collectionId}/apply-coupon`, data.values)
      .then((response) => {
        if (response.status === 200) {
          notyf.success(response.data.message)
          dispatch({
            type: loginType.PROMO_CODE_DATA,
            payload: {
              productPrice: response.data.data.price,
              oldPrice: response.data.data.original_price,
              productKey: data.values.hash_id,
              promo_code: data.values.promo_code,
            },
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: loginType.PROMO_CODE_DATA_ERROR,
          payload: {
            productKeyError: data.values.hash_id,
          },
        })
      });
  }
}

export const promoCodeCancel = (value) => {
  return async (dispatch) => {
    dispatch({
      type: loginType.PROMO_CODE_DATA_CANCEL,
      payload: {
        cancelKey: value,
      },
    },
    )
  }
}

export const clearPromoCodeError = (value) => {
  return async (dispatch) => {
    dispatch({
      type: loginType.CLEAR_PROMO_CODE_ERROR,
      payload: {
        clearKey: value,
      },
    },
    )
  }
}

import styled from "styled-components";

export const TraitsLeftMenuList = styled.ul`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 5px;

    h5{
        font-family: 'Roboto Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: #CAD6EC;
        margin:0;
    }
`;
export const TraitsLeftMenuListItem = styled.li`
    list-style: none;
    width: 100%;
    height: 37px;
    border: 1px solid #CAD6EC;
    border-radius: 5px;
    margin: 5px 0;
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    background: ${props => props.selectedItem ? '#CAD6EC' : 'rgba(157, 157, 157, 0.04)'};
    position: relative;
`;
export const TraitsPoligonLeftMenuListItem = styled.img`
    position: absolute;
    top: 5px;
    right: -30px;
    z-index: 10;
`;

export const TraitsDashbordListItemButton = styled.button`
    border: none;
    width: 100%;
    height: 100%;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    span{
        padding: 1px 6px;
        background: #1D1E22;
        border-radius: 3px;
        font-size: 10px;
        align-items: center;

        color: #CAD6EC;
    }
`;

export const TraitsDashbordListItemName = styled.h6`
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 20px;
    color:${props => props.selectedItem ? '#1D1E22' : '#CAD6EC'} ;
    margin: 0;

`;

import React from 'react';
import CollectionDetails from '../collectionDetails';
import MentingOptions from '../mentingOptions';
import PublishColection from '../publishColection';
import { useSelector } from 'react-redux';
import Reporting from '../reporting';
import ManageContractMenu from "../rightMenu/manageContractMenu";

function ReportingDashboard() {
    const state = useSelector(state => state?.smartContractsReducer)

    return (
        <>
            <div className="content-main left-menu smart-left-content">
                {state?.manageContract === false
                    && state?.publishCollection === false
                    && state?.marketingKit === false
                    && state?.reporting === false
                    && (
                        <Reporting />
                    )}
                {state?.manageContract === true && (
                    <>
                        <MentingOptions />
                        <ManageContractMenu />
                    </>
                )}
                {state?.publishCollection === true && (
                    <PublishColection />
                )}
                {state?.marketingKit === true && (
                    <CollectionDetails />
                )}
                {state?.reporting === true && (
                    <Reporting />
                )}
            </div>
        </>
    )
}

export default ReportingDashboard
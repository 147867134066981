import { loginType } from "../../constants/auth";

const initialState = {
  userId: "",
  token: localStorage.getItem("token"),
  authenticate: localStorage.getItem("token") ? true : false,
  error: null,
  userData: {
    first_name: '',
    last_name: '',
    email: '',
    currentLimit: null
  },
  products: {},

};

const authReducer = (state = initialState, action) => {
  switch (action.type) {

    case loginType.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        authenticate: true,
      };

    case loginType.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        authenticate: false,
      };

    case loginType.USER_DATA:
      const { user_data, products_praic } = action.payload;
      return {
        ...state,
        userData: user_data,
        products: products_praic,
      };

    case loginType.UPDATE_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email
        },
      };

    case loginType.PROMO_CODE_DATA:
      const { productKey, productPrice, oldPrice, promo_code } = action.payload;
      delete state.products[productKey].error
      return {
        ...state,
        products: {
          ...state.products,
          [productKey]: {
            ...state.products[productKey],
            old_price: oldPrice,
            price: productPrice,
            promo_code: promo_code,
            disabled: true,
          }
        }
      }

    case loginType.PROMO_CODE_DATA_ERROR:
      const { productKeyError } = action.payload
      return {
        ...state,
        products: {
          ...state.products,
          [productKeyError]: {
            ...state.products[productKeyError],
            error: true,
          }
        }
      }

    case loginType.PROMO_CODE_DATA_CANCEL:
      const { cancelKey } = action.payload;
      let price = +state.products[cancelKey]?.old_price;
      delete state.products[cancelKey]?.old_price;
      delete state.products[cancelKey]?.disabled;
      delete state.products[cancelKey]?.promo_code;
      return {
        ...state,
        products: {
          ...state.products,
          [cancelKey]: {
            ...state.products[cancelKey],
            price: price,
          }
        }
      }

    case loginType.CLEAR_PROMO_CODE_ERROR:
      const { clearKey } = action.payload;
      delete state.products[clearKey].error

      return state;

    case loginType.GET_ACCOUNT_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          currentLimit: action.payload.currentLimit,
        }
      }

    default:
      return state;

  }
};
export default authReducer;

import React from 'react';
import { Modal } from 'react-bootstrap';

function ConfirmWithdrawModal({ showModal, setShowModal }) {

  return (
    <>
      <Modal show={showModal.withdrawlModal} onHide={() => setShowModal({ withdrawlModal: false })} className='delete-modal-main'>
        <Modal.Body className=''>
          <div className='delete-modal-content'>
            <h2>Confirm Withdrawl Amount</h2>
            <div className='modal-devider'></div>
            <div className='contract-revenue'>
              <div className='total-revenue'>
                <span>AVAILABLE:</span>
                <input
                  name="total"
                  disabled
                  type="text"
                  placeholder="12.34 ETH ~ $839,482.01"
                  value='12.34 ETH ~ $839,482.01 '
                />
              </div>
            </div>

            {/* <button className='btn-amount'>0 ETH ~ $1,500 USD</button> */}
            <label className='amount-label'>AMOUNT TO WITHDRAW</label>
            <input type="text" className='btn-amount' placeholder="0 ETH ~ $1,500 USD" />
            <button className='btn-connect-wlt'>CONNECT WALLET</button>
            <button className='btn-connect-wlt'>CONFIRM WITHDRAWL</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConfirmWithdrawModal
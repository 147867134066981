import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from "../../redux/action"


const Header = ({
  userData,
  getUserData,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOut = () => {
    localStorage.removeItem('token');
    navigate("/");
  }

  return (
    <div className="header-main">
      <div className="header-logo">
        <Link to='/dashboard'><img alt='' src="/images/CodelessNFT-Logo-Light.png" /></Link>
      </div>

      <div className='header-right'>
        <div>
          {
            !!userData.currentLimit && userData.currentLimit > 0
              ?
              <div className='question-mark-header'>
                <p className='question-mark-header-text'>{userData.currentLimit} collections left</p>
              </div>

              : <></>

          }
        </div>

        {/* <div className='question-mark-header'>
                    <img src='/images/question-mark.png' alt="" />
                </div> */}
        <Dropdown className='question-mark-main' align="end" title="Dropdown end">
          <Dropdown.Toggle className='question-mark-header'>
            <img src='/images/question-mark.png' alt="" />
            <Dropdown.Menu>
              <div className='header-menu-open'>
                {/* <div className=''> */}
                <Dropdown.Item onClick={() => navigate("/smartcontracts")}><img src='/images/join-discover.png' alt="" /> Join our Discord</Dropdown.Item>
                {/* <Dropdown.Item onClick={()=> navigate("/nft/traits")}><img src='/images/Knowledgebase.png' alt="" /> Knowledgebase</Dropdown.Item> */}
                <Dropdown.Item href="#"><img src='/images/submit-ticket.png' alt="" /> Submit a Ticket</Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown.Toggle>
        </Dropdown>

        <div className="header-profile">
          <Dropdown title="Dropdown button">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className='header-profile-name'>
                <img src="/images/user-image.jpg" alt="" />
                <span>{userData.first_name} {userData.last_name}</span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className='header-menu-open' id="header-menu">
                {/* <div className=''> */}
                {/* <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                        <Dropdown.Item onClick={() => navigate("/smartcontracts")}><img src='/images/join-discover.png' alt="" /> Join our Discord</Dropdown.Item>
                                        <Dropdown.Item href="#"><img src='/images/Knowledgebase.png' alt="" /> Knowledgebase</Dropdown.Item>
                                        <Dropdown.Item href="#"><img src='/images/submit-ticket.png' alt="" /> Submit a Ticket</Dropdown.Item>
                                    </div> */}
                <Dropdown.Item onClick={() => navigate("/account")}><img src='/images/my-account.png' alt="" /> My Account</Dropdown.Item>
                <Dropdown.Item href="#" onClick={handleOut} ><img src='/images/log-out.png' alt="" /> Log Out</Dropdown.Item>
                {/* </div> */}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  userData: state?.auth?.userData,
})

const mapDispatchToProps = (dispatch) => ({
  getUserData: (value) => dispatch(actions.getUserData(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)

import { Container, Modal } from "react-bootstrap";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  .modal-content {
    margin: auto;
    padding: 30px;
    width: 550px;
    height: 200px;
    background: #1f2328;
    color: #cad6ec;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 7px #c0c0c04a;

    // width: ${({ width }) => width || 'max-content'};
  }
`;

export const StyledContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: normal;
`;

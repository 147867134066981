import { collectionDataType } from "../../constants/collections"

const initialState = {
  collectionData: [],
  imageCollection: [],
  traits: {
    cards: [],
    selectedTrait: {
      images: [],
      tags: [],
    },
  },
  notValidImages: [],
  collections: [],
  chipData: [],
  characterData: [],
  isDataEmpty: false,
  showProgress: {

  },
}

const collectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case collectionDataType.GET_COLLECTION_DATA:
      return {
        ...state,
        collectionData: action.payload,
      }

    case collectionDataType.GET_UNACTIVE_COLLECTION_DATA:
      return {
        ...state,
        collectionData: action.payload,
      }

    case collectionDataType.CREATE_COLLECTION_DATA:
      return {
        ...state,
        collectionData: {
          ...state.collectionData,
          data: [action.payload, ...state.collectionData.data],
        }
      }

    case collectionDataType.EDIT_COLLECTION_DATA:
      const editDataIndex = state.collectionData.data.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        collectionData: {
          ...state.collectionData,
          data: [
            ...state.collectionData.data.map((item, index) => index === editDataIndex ? action.payload : item)
          ]
        }

      }

    case collectionDataType.UPDATE_IMAGE_DATA:
      const index = state.collectionData.data.findIndex((item) => item.id === action.payload.id)
      const updateData = {
        ...state.collectionData.data[index],
        logo: action.payload.image
      }

      return {
        ...state,
        collectionData: {
          ...state.collectionData,
          data: [
            ...state.collectionData.data.map((item, i) => i === index ? updateData : item)
          ]
        }

      }

    case collectionDataType.DELETE_COLLECTION:
      return {
        ...state,
        collectionData: {
          ...state.collectionData,
          data: [
            ...state.collectionData.data.filter((item) => item.type !== action.payload)
          ]
        }
      }
    case collectionDataType.GET_IMAGE_COLLECTION_DATA:

      return { ...state, imageCollection: action.payload }

    case collectionDataType.USER_DATA_TYPE:
      return {
        ...state,
        userData: action.payload
      }
    case collectionDataType.CREATE_CHIP_DATA:
      return {
        ...state,
        chipData: [action.payload, ...state.chipData]
      }
    case collectionDataType.GET_CHIP_DATA:
      return { ...state, chipData: action.payload }
    case collectionDataType.CREATE_CHARACTER_DATA:
      return {
        ...state,
        characterData: [action.payload, ...state.characterData]
      }
    case collectionDataType.GET_CHARACTER_DATA:
      return {
        ...state, characterData: action.payload
      }

    case collectionDataType.DELETE_CHARACTER_DATA:
      return {
        ...state,
        characterData: state.characterData.filter((item) => item?.id !== action.payload)
      }

    case collectionDataType.GET_TRAIT_CARD_DATA:
      const { data, id } = action.payload;
      const trait = data.find(item => item.id === id);
      return {
        ...state,
        imageCollection: !data[0]?.images[0]?.data.length ? [] : state.imageCollection,
        traits: {
          cards: data,
          selectedTrait: id.length > 0
            ? {
              ...trait,
              images: trait.images[0].data || [],
              tags: trait.tags[0].data || [],
            }
            : {
              ...data[0],
              images: data[0]?.images[0]?.data || [],
              tags: data[0]?.tags[0]?.data || [],
            }
        },
        isDataEmpty: false,
      }

    case collectionDataType.DELETE_TRAIT_CARD:
      return {
        ...state,
        traits: {
          cards: state.traits.cards.filter(item => item.id !== action.payload.id).map((value, index) => {
            value.position = index + 1
            return value
          }),
          selectedTrait: {
            ...state.traits.cards[action.payload.index],
            images: state.traits.cards[action.payload.index]?.images[0]?.data,
            tags: state.traits.cards[action.payload.index]?.tags[0].data
          }
        }
      }

    case collectionDataType.DELETE_TRAIT_CARD_IMAGE_CALLECTION:
      return {
        ...state,
        imageCollection: action.payload,
      }

    case collectionDataType.CREATE_IMAGE_COLLECTION:
      const { traitID, image, imagesRarity } = action.payload;
      const imageData = state.traits.cards.find((item) => item.id === traitID)?.images[0]?.data;
      imageData?.push(image);
      imageData?.forEach((item) => {
        imagesRarity.forEach((value) => {
          if (item.id === Object.keys(value)[0]) {
            item.rarity = value[Object.keys(value)[0]];
          }
        })
      })
      return {
        ...state,
        traits: {
          ...state.traits,
        }

      };


    case collectionDataType.NOT_VALID_IMAGES:
      const { not_valid_images } = action.payload;
      return {
        ...state,
        notValidImages: [
          ...state.notValidImages,
          not_valid_images,
        ]
      }

    case collectionDataType.GET_PREVIEW_COLLECTION_IMAGES:
      return {
        ...state,
        imageCollection: [
          ...state.imageCollection,
          ...action.payload.images
        ],
        isDataEmpty: action.payload.empty
      }

    case collectionDataType.CREATE_TRAIT_CARD_COLLECTION:
      return {
        ...state,
        traits: {
          selectedTrait: {
            ...action.payload,
            images: action.payload.images[0].data,
            tags: action.payload.tags[0].data
          },
          cards: [
            ...state.traits.cards,
            action.payload,
          ]
        },
        notValidImages: [],
      }

    case collectionDataType.DRAG_AND_DROP_TRAIT_CARD:
      let changeSelectTrait = action.payload.find((item) => item.id === state.traits.selectedTrait.id)
      return {
        ...state,
        traits: {
          ...state.traits,
          cards: action.payload,
          selectedTrait: {
            ...changeSelectTrait,
            tags: changeSelectTrait?.tags[0].data,
            images: changeSelectTrait?.images[0].data,
          }
        }
      }
    case collectionDataType.CHANGE_SELECTED_ITEM:
      return {
        ...state,
        traits: {
          ...state.traits,
          selectedTrait: action.payload
        },
        notValidImages: [],
      }

    case collectionDataType.CREATE_TRAIT_TAGS:
      state.traits.cards.find((item) => item.id === action.payload.id)?.tags[0]?.data?.push(action.payload.tag);
      return {
        ...state,
        traits: {
          ...state.traits,
        }
      }

    case collectionDataType.DELETE_TRAIT_TAGS:
      const traitTags = state.traits.cards.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            tags: [{ data: item.tags[0].data.filter((val) => val.id !== action.payload.tagID) }],
          }
        } return item;
      })
      return {
        ...state,
        traits: {
          cards: traitTags,
          selectedTrait: {
            ...state.traits.selectedTrait,
            tags: state.traits.selectedTrait.tags.filter((item) => item.id !== action.payload.tagID)
          }
        }
      }

    case collectionDataType.UPADATE_RARITY_SETTINGS:
      const { payload: { updatedImages, payload: { rarity, distribution } } } = action
      const updatedCards = state.traits.cards.map((card) => (
        card.id === state.traits.selectedTrait.id ? { ...card, rarity, distribution } : card
      ))
      return {
        ...state,
        traits: {
          ...state.traits,
          cards: updatedCards,
          selectedTrait: {
            ...state.traits.selectedTrait,
            images: updatedImages,
            distribution,
            rarity,
          }
        }
      }

    case collectionDataType.UPDATE_IMAGE_VARIATION_NAME:
      const updatedVariationName = state.traits.selectedTrait.images.map((image) => (
        image.id === action.payload.id ? { ...image, variation: action.payload.name } : image
      ))
      state.traits.cards.find((item) => item.id === action.payload.traitID).images[0].data = updatedVariationName
      return {
        ...state,
        traits: {
          ...state.traits,
          selectedTrait: {
            ...state.traits.selectedTrait,
            images: updatedVariationName,
          }
        }
      }

    case collectionDataType.UPDATE_TRAIT_POSITION:
      const prevTrait = state.traits.cards[action.payload.prevPosition - 1];
      state.traits.cards[action.payload.prevPosition - 1] = state.traits.cards[action.payload.nextPosition - 1];
      state.traits.cards[action.payload.nextPosition - 1] = prevTrait;
      state.traits.cards.find((item) => item.id === action.payload.id).name = action.payload.name;

      return {
        ...state,
        traits: {
          cards: state.traits.cards.map((item, index) => {
            item.position = index + 1;
            return item;
          }),
          selectedTrait: {
            ...state.traits.selectedTrait,
            position: action.payload.nextPosition,
            name: action.payload.name,
          }
        }
      }

    case collectionDataType.CLEAR_TRAITS_DATA:
      return {
        ...state,
        traits: {
          cards: [],
          selectedTrait: {},
        }
      }

    case collectionDataType.CLEAR_IMAGES_COLLECTION:
      return {
        ...state,
        imageCollection: [],
      }

    case collectionDataType.GET_PREVIEW_COLLECTION:
      return {
        ...state,
        imageCollection: action.payload,
        isDataEmpty: false,
      }

    case collectionDataType.EXPORT_STATUS:
      const { export_status, collectionIdSatus } = action.payload
      return {
        ...state,
        showProgress: {
          ...state.showProgress,
          [collectionIdSatus]: {
            ...state.showProgress[collectionIdSatus],
            exportStatusData: export_status.sub_status && !export_status.sub_status.length
              ? export_status : state.exportStatusData,
            exportData: false,
          }
        }
      }

    case collectionDataType.EXPORT_DATA:
      const { collectionId, url } = action.payload
      state.collectionData.data.map(item => {
        if (item.id === collectionId && !!url) {
          item.archive = url
        }
        return item
      })
      return {
        ...state,
        showProgress: {
          ...state.showProgress,
          [collectionId]: {
            ...state.showProgress[collectionId],
            collectionId: collectionId,
            exportData: !url,
          },
        }

      }

    case collectionDataType.CHANGE_TIMEOUT_ID:
      const { value, show, changeCollectionId } = action.payload
      return {
        ...state,
        showProgress: {
          ...state.showProgress,
          [changeCollectionId]: {
            ...state.showProgress[changeCollectionId],
            timeoutId: value,
            show: show,
            exportStatusData: {
              sub_status: null
            }
          }
        },

      }

    case collectionDataType.DELETE_EXPORT_STATUS_DATA:
      delete state.showProgress[action.payload]
      return {
        ...state
      }

    default:
      return state
  }
}

export default collectionReducer

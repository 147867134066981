import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  PrevievCollectionButtonBox,
  PrevievCollectionDiv,
  PrevievCollectionDivTitle,
  PrevievCollectionImage,
  PrevievCollectionImageBox,
  PrevievCollectionWatermark,
} from './style';
import ExportBarButton from '../exportBarButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import 'notyf/notyf.min.css';

function PreviewCollectionModal({
  previewCollectionShow,
  setPreviewCollectionShow,
  imageCollection,
  load,
  onLoadMoreImages,
  handleConfirmExport,
  handelClick,
  collectionID,
  disable,
  // confirmShowModal,
  setConfirmShowModal,
  collectionArchive,
  // exportStatusDataCollection
}) {
  return (
    <>
      <Modal
        show={previewCollectionShow}
        onHide={() => { setPreviewCollectionShow(!previewCollectionShow) }}
        className='preview-modal-main'>
        <Modal.Body>
          <PrevievCollectionDiv>
            <PrevievCollectionDivTitle>
              <h2>Preview Collection</h2>
              <p>Make sure you have set your <Link to='#'>rarity settings</Link> and confirm that your layers are being rendered properly in the preview below. </p>
            </PrevievCollectionDivTitle>
            <PrevievCollectionImageBox onScroll={onLoadMoreImages}>
              {imageCollection.map((item, index) => (
                <PrevievCollectionImage key={index}>
                  <PrevievCollectionWatermark></PrevievCollectionWatermark>
                  {item.map((img, i) =>
                    <img
                      src={img}
                      key={i}
                      alt=""
                    />
                  )}
                </PrevievCollectionImage>
              ))}
              {load ? <div className='lodader-body-prev'>
                <Box className='loader-main-prev'>
                  <CircularProgress />
                </Box>
              </div> : <></>}
            </PrevievCollectionImageBox>
            <PrevievCollectionButtonBox>
              <ExportBarButton
                handleConfirmExport={handleConfirmExport}
                handelClick={handelClick}
                disable={disable}
                setConfirmShowModal={setConfirmShowModal}
                collectionArchive={collectionArchive}
                setPreviewCollectionShow={setPreviewCollectionShow}
              />
            </PrevievCollectionButtonBox>
          </PrevievCollectionDiv>
        </Modal.Body>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  imageCollection: state?.collectionReducer?.imageCollection,
});

export default connect(mapStateToProps)(PreviewCollectionModal);

import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ConfirmWithdrawModal from '../confirmWithdrawModal';
import WithdrawFundModalFile from '../withdrawFundModalFile';

const ManageContractMenu = () => {
    const [showModal, setShowModal] = useState({
        widthDrawlModal: false,
        fundModal: false
    })

    return (
        <div className='right-menu-main'>
            <div className='withdrow-right-sidebar right-menu-content'>
                <label htmlFor="CollectionName" className='miting-tabs'>
                    <label>MINTING <img src='/images/trait-icon.png' alt="" /></label>
                    <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mb-3" onSelect={() => setShowModal({ fundModal: true })}>
                        <Tab eventKey="1" title="Enable">
                        </Tab>
                        <Tab eventKey="2" title="Disable" >
                        </Tab>
                    </Tabs>
                </label>
                <label htmlFor="NFTname" className="">
                    <label>SMART CONTRACT ADDRESS <img src='/images/trait-icon.png' alt="" /></label>
                    <input
                        name="NFTname"
                        type="text"
                        className='copy-input'
                        placeholder="0xBC4CA0EdA7647A8aTHB1c2E11842936f13D"
                    />
                    <img className="copy-icon" src='/images/copy-icon.png' alt="" />
                </label>
                <div className='contract-revenue'>
                    <label>CONTRACT REVENUE <img src='/images/trait-icon.png' alt="" /></label>
                    <div className='total-revenue'>
                        <span>TOTAL:</span>
                        <input
                            name="total"
                            type="text"
                            placeholder="35.34 ETH ~ $1,439,029,48"
                        />
                    </div>
                    <div className='total-revenue'>
                        <span>AVAILABLE:</span>
                        <input
                            name="total"
                            type="text"
                            placeholder="12.34 ETH ~ $839,482.01"
                        />
                    </div>
                </div>
                <button className='withdrow-btn' onClick={() => setShowModal({ withdrawlModal: true })}>WITHDRAW</button>
                <label htmlFor="TraitDescription">
                    <label>AIRDROP <img src='/images/trait-icon.png' alt="" /></label>
                    <textarea
                        name="TraitDescription"
                        type="text"
                        placeholder="A collection of 10,000 unique clowns ready to take the metaverse by storm."
                    />
                </label>

                <button className='withdrow-btn'>APPLY</button>

            </div>
            <ConfirmWithdrawModal showModal={showModal} setShowModal={setShowModal} />
            <WithdrawFundModalFile showModal={showModal} setShowModal={setShowModal} />
        </div >
    )
};

export default ManageContractMenu;

import React from 'react';
import { Modal } from 'react-bootstrap';

function WithdrawFundModalFile({ showModal, setShowModal }) {

    return (
        <>
            <Modal show={showModal?.fundModal} onHide={() => setShowModal({ fundModal: false })} className='delete-modal-main'>
                <Modal.Body>
                    <div className='delete-modal-content'>
                        <h2 className=''>Withdraw Funds</h2>
                        <div className='modal-devider'></div>
                        <p className='mb-2'> Are you sure you want to disable minting?  This will not allow people to mint your NFT. </p>

                        <button className='btn-connect-wlt'>DISABLE MINTING</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WithdrawFundModalFile
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import RaritySettingsIcon from '../../../asset/icons/rarity-settings-icon.svg';
import PreviewCollectionIcon from '../../../asset/icons/preview-collection-icon.svg';
import ExportCollectionIcon from '../../../asset/icons/export-collection-icon.svg';
import SaveAsDeraftIcon from '../../../asset/icons/save-as-deraft-icon.svg';
import EditProjectIcon from '../../../asset/icons/edit-project-icon.svg';
import {
  TraitsLeftMenu, TraitsLeftMenuBackGroundButton,
  TraitsLeftMenuBorderedButton, TraitsLeftMenuBottomSide,
  TraitsLeftMenuButtons, TraitsLeftMenuLine, TraitsLeftMenuListAddDiv, TraitsLeftMenuTopSide
} from './style';
import Container from '../../../components/DragAndDrop/index';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import RaritySettings from '../../../components/RaritySettings';
import { connect } from 'react-redux';
import * as actions from "../../../redux/action"
import PreviewCollectionModal from '../../previewCollectionModal';
import AddCollection from '../../createCollection';
import ConfirmOrderModal from '../../confirmOrderModal';
import ExportCollectionProgressBar from '../../ExportCollectionProgressBar';
import { useExport } from '../../../utils/useExport';


function LeftMenu({
  traitsCard,
  setTraitsCard,
  changeSelectedItem,
  createTraitCardItem,
  dragAndDropTraitCard,
  collectionData,
  saveAsDraft,
  setImage,
  getPreviewCollection,
  getPreviewCollectionImages,
  id,
  lazyLoadDataEmpty,
  showProgress,
  changeTimeoutId,
  exportCollectionStatus,
  exportCollection,
  disable,
  setDisable,
  userData
  // defaultButton,
  // setDefaultButton,
}) {
  const [raritySettingsShow, setRaritySettingsShow] = useState(false);
  const [previewCollectionShow, setPreviewCollectionShow] = useState(false);
  const [traitName, setTraitName] = useState('');
  const [isDragEnded, setIsDragEnded] = useState(false);
  const [editCollection, setEditCollection] = useState(false);
  const [collectionEditData, setCollectionEditData] = useState({});
  const [confirmShowModal, setConfirmShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [prevLoad, setPrevLoad] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [firstRequest, setFirstRequest] = useState(true);
  const [page, setPage] = useState(1);
  const [checked, setChecked] = useState(false);
  const [dataSuccsess, setDataSuccsess] = useState(false);
  const { currentLimit } = userData

  useExport(`${id}`);

  useEffect(() => {
    if (!isDragEnded) return;
    dragAndDropTraitCard(
      {
        id: collectionData.id,
        values: traitsCard.cards,
      }
    );
    setIsDragEnded(false);
  }, [isDragEnded, traitsCard.cards, dragAndDropTraitCard, collectionData])

  useEffect(() => {
    setErrorMessage('')
  }, [traitsCard?.selectedTrait])

  const addTraits = () => {
    if (traitName === '') {
      return;
    }

    const traitItem = {
      name: traitName,
      position: traitsCard.cards.length + 1,
    };

    createTraitCardItem({
      id: collectionData?.id,
      values: traitItem
    })
    setTraitName('');
  }

  const handleRaritySettings = () => {
    setPreviewCollectionShow(!previewCollectionShow);
    setPrevLoad(true);
    getPreviewCollection(collectionData?.id).then(() => {
      setPrevLoad(false);
      setLastScrollTop(0);
      setPage(1)
    });
  }


  const changeBackground = (id) => {
    const selectedTraits = traitsCard.cards.find((item) => item.id === id)
    changeSelectedItem(selectedTraits);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (traitName === '') {
        return;
      }
      addTraits();
    }
  }


  const loadMoreImages = (e) => {
    const target = e.target;
    if (target.scrollTop > (target.scrollHeight - 450) && target.scrollTop > lastScrollTop && firstRequest && !lazyLoadDataEmpty) {
      setFirstRequest(false);
      setPrevLoad(true);
      const data = {
        collectionID: collectionData?.id,
        traitId: traitsCard.selectedTrait.id,
        page: page,
      }
      setTimeout(() => {
        getPreviewCollectionImages(data).then(() => {
          setPage(page + 1);
          setFirstRequest(true);
          setPrevLoad(false);
        })
      }, 1500)
      setLastScrollTop(target.scrollTop)
    }
    if (lazyLoadDataEmpty) {
      setPrevLoad(false);
    }
  }

  useEffect(() => {
    if (showProgress[id]?.show) {
      setDisable(true)
    }
  }, [])

  useEffect(() => {
    if (dataSuccsess) {
      handleConfirmExport(id);
    }
  }, [dataSuccsess])

  useEffect(() => {
    if (showProgress[id]?.exportData && !!showProgress[id]) {
      const timeoutId = setInterval(() => {
        exportCollectionStatus(id)
      }, 3000)
      changeTimeoutId({
        value: timeoutId,
        show: true,
        changeCollectionId: id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProgress[id]?.exportData])

  useEffect(() => {
    if (!!showProgress[id]?.exportStatusData) {
      if (!!showProgress[id]?.exportStatusData.sub_status && !!showProgress[id] && !!showProgress[id]?.timeoutId) {
        const { sub_status } = showProgress[id]?.exportStatusData;

        setDisable(true);

        if (sub_status?.status === '1' || sub_status?.status === '2') {
          exportCollection({ collectionId: id });
          clearInterval(showProgress[id]?.timeoutId);
          setTimeout(() => {
            setDisable(false);
          }, 3000)

          setTimeout(() => {
            changeTimeoutId({
              value: null,
              show: false,
              changeCollectionId: id,
            }, 4000)

          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProgress[id]?.exportStatusData])

  const handleConfirmExport = (id) => {
    exportCollection({
      collectionId: id,
    })
  }



  useEffect(() => {
    if (parseInt(collectionData?.export) < 0 && !showProgress[id]?.show) {
      handleConfirmExport(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionData])

  const handelExportArchive = () => {
    if (Object.keys(collectionData).length > 0 && !!collectionData.archive) {
      window.open(collectionData.archive, "_self")
      return
    }

    if (currentLimit === 0) {
      setConfirmShowModal(!confirmShowModal)
      return
    }

    handleConfirmExport(id)
  }

  return (
    <>
      <TraitsLeftMenu>
        <TraitsLeftMenuTopSide backend={HTML5Backend}>
          <TraitsLeftMenuButtons>
            {/* <TraitsLeftMenuButton defaultButton={defaultButton} onClick={() => setDefaultButton(true)}>BASIC</TraitsLeftMenuButton>
            <TraitsLeftMenuButton defaultButton={!defaultButton} onClick={() => setDefaultButton(false)}>ADVANCED</TraitsLeftMenuButton> */}
          </TraitsLeftMenuButtons>
          <DndProvider backend={HTML5Backend}>
            <Container
              traitsCard={traitsCard}
              setTraitsCard={setTraitsCard}
              onDragEnd={() => setIsDragEnded(true)}
              changeBackground={changeBackground}
              setImage={setImage}
            />
          </DndProvider>
          <TraitsLeftMenuListAddDiv disabled={disable}>
            <form onSubmit={(e) => e.preventDefault()}>
              <button type='button' className='btn-add-trait' onClick={addTraits} disabled={disable}>+</button>
              <input
                type='text'
                placeholder='Add Trait'
                value={traitName}
                onChange={(e) => setTraitName(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={disable}
              />
            </form>
          </TraitsLeftMenuListAddDiv>
          <TraitsLeftMenuBorderedButton disabled={disable}>
            <button className='btn-rarity-settings' disabled={disable} onClick={() => setRaritySettingsShow(!raritySettingsShow)}>
              <img className='traits-dashbord-icon' src={RaritySettingsIcon} alt="" />
              RARITY SETTINGS
            </button>
          </TraitsLeftMenuBorderedButton>
          <TraitsLeftMenuLine></TraitsLeftMenuLine>
          <TraitsLeftMenuBorderedButton disabled={disable}>
            <button className='btn-rarity-settings' onClick={handleRaritySettings} disabled={disable}>
              <img className='traits-dashbord-icon' src={PreviewCollectionIcon} alt="" />
              PREVIEW COLLECTION
            </button>
          </TraitsLeftMenuBorderedButton>
          <TraitsLeftMenuBackGroundButton disabled={disable}>
            <button className='btn-rarity-settings ' onClick={handelExportArchive} disabled={disable}>
              <img className='traits-dashbord-icon' src={ExportCollectionIcon} alt="" />
              EXPORT COLLECTION
            </button>
          </TraitsLeftMenuBackGroundButton>
        </TraitsLeftMenuTopSide>
        <TraitsLeftMenuBottomSide>
          <TraitsLeftMenuBorderedButton style={{ border: '1px solid #CAD6EC' }} disabled={disable}>
            <button className='btn-rarity-settings' onClick={() => saveAsDraft(collectionData?.id)} disabled={disable}>
              <img className='traits-dashbord-icon' src={SaveAsDeraftIcon} alt="" />
              SAVE AS DRAFT
            </button>
          </TraitsLeftMenuBorderedButton>
          <TraitsLeftMenuBackGroundButton disabled={disable}>
            <button className='btn-rarity-settings ' onClick={() => {
              setEditCollection(!editCollection);
              setCollectionEditData(collectionData);
            }}
              disabled={disable}>
              <img className='traits-dashbord-icon' src={EditProjectIcon} alt="" />
              EDIT PROJECT
            </button>
          </TraitsLeftMenuBackGroundButton>
        </TraitsLeftMenuBottomSide>
      </TraitsLeftMenu>
      {
        showProgress[id]?.show ? <ExportCollectionProgressBar
          showProgress={showProgress[id]?.show}
          exportStatus={showProgress[id]?.exportStatusData.sub_status}
        /> : <></>
      }
      <RaritySettings
        show={raritySettingsShow}
        setShow={setRaritySettingsShow}
        traitsCard={traitsCard}
        changeBackground={changeBackground}
        collectionID={collectionData?.id}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <PreviewCollectionModal
        previewCollectionShow={previewCollectionShow}
        setPreviewCollectionShow={setPreviewCollectionShow}
        traitsCardImageCollection={traitsCard?.selectedTrait?.images}
        load={prevLoad}
        collectionID={collectionData?.id}
        collectionArchive={collectionData?.archive}
        onLoadMoreImages={loadMoreImages}
        exportStatusDataCollection={collectionData?.export}
        handleConfirmExport={handleConfirmExport}
        disable={disable}
        confirmShowModal={confirmShowModal}
        setConfirmShowModal={setConfirmShowModal}
      />
      <AddCollection
        addCollection={editCollection}
        setAddCollection={setEditCollection}
        collectionEditData={collectionEditData}
        setCollectionEditData={setCollectionEditData}
      />
      <ConfirmOrderModal
        confirmShowModal={confirmShowModal}
        setConfirmShowModal={setConfirmShowModal}
        collectionId={collectionData?.id}
        collectionArchive={collectionData?.archive}
        exportStatusDataCollection={collectionData?.export}
        uniqueImages={collectionData?.images}
        checked={checked}
        setChecked={setChecked}
        dataSuccsess={dataSuccsess}
        setDataSuccsess={setDataSuccsess}
        handleConfirmExport={handleConfirmExport}
        disable={disable}
      />
    </>
  );
}
const mapStateToProps = (state) => ({
  lazyLoadDataEmpty: state?.collectionReducer?.isDataEmpty,
  showProgress: state?.collectionReducer?.showProgress,
  userData: state?.auth?.userData,
})

const mapDispatchToProps = (dispatch) => ({
  createTraitCardItem: (data) => dispatch(actions.createTraitCardItem(data)),
  dragAndDropTraitCard: (data) => dispatch(actions.dragAndDropTraitCard(data)),
  changeSelectedItem: (values) => dispatch(actions.changeSelectedItem(values)),
  saveAsDraft: (data) => dispatch(actions.saveAsDraft(data)),
  getPreviewCollection: (id) => dispatch(actions.getPreviewCollection(id)),
  getPreviewCollectionImages: (data) => dispatch(actions.getPreviewCollectionImages(data)),
  changeTimeoutId: (values) => dispatch(actions.changeTimeoutId(values)),
  exportCollection: (id) => dispatch(actions.exportCollection(id)),
  exportCollectionStatus: (id) => dispatch(actions.exportCollectionStatus(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);

import styled from "styled-components";

export const RaritySettingsDiv = styled.div`
    width: 100%;
    height: 100%;
    background:none;
`;

export const RaritySettingsDivTitle = styled.div`
    width: 95%;
    height: 100px;
    border-bottom: 1px solid #2E343A;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 10px 0;
    margin: 0 auto;

    h2 {
        font-family: 'Roboto Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #CAD6EC;
    }

    p {
        width: 574px;
        height: 18px;
        font-family: 'Roboto Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #CAD6EC;
    }
`;

export const StyledCollectionInfoContainer = styled.div`
    width: 95%;
    height: 70px;
    margin: 10px auto;

    form {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }
`;

export const StyledCollectionInfoContainerInputs = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;


    div{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 10px;
        position: relative;

        label {
            font-family: 'Roboto Regular';
            font-style: normal;
            font-weight: 900;
            font-size: 10px;
            line-height: 12px;
            color: #4F5B76;
            margin-bottom: 8px;
        }

        select {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 12px;
            width: 287px;
            height: 43px;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
            border-radius: 6px;
            font-family: 'Roboto Regular';
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 15px;
            color: #000000;
            box-sizing: border-box;
            outline: none;
        }

        input {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 12px;
            width: 129px;
            height: 43px;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
            border-radius: 6px;
            outline: none;
            border: none;
        }
    }
`;

export const PercentageContainerTrait = styled.span`
    visibility:  ${(p) => p.traitPositonLeft > 15 ? 'visible' : 'hidden'};
    position: absolute;
    top: 29px;
    left: ${(p) => p.traitPositonLeft}px;
    color:black;
`;

export const StyledCollectionInfoContainerButtons = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h6 {
        font-family: 'Roboto Regular';
        font-style: normal;
        font-weight: 900;
        font-size: 10px;
        line-height: 12px;
        color: #4F5B76;
    }

    div {
        width: 100%;
        display: flex;
        justify-content: space-between;

        button {
            box-sizing: border-box;
            width: 159px;
            height: 43px;
            background: rgba(157, 157, 157, 0.04);
            border: 1px solid #CAD6EC;
            border-radius: 5px;
            font-family: 'Roboto Regular';
            font-style: normal;
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            color: #CAD6EC;

        }
    }
`;

export const RaritySettingsImageColectionBox = styled.div`
    width: 95%;
    height: 320px;
    margin: 0 auto;


    h6{
        font-family: 'Roboto Regular';
        font-style: normal;
        font-weight: 900;
        font-size: 10px;
        line-height: 12px;
        color: #4F5B76;
    }
`;

export const RaritySettingsImageColection = styled.div`
    width: 100%;
    height: 312px;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 10px 0;
`;
export const ErrorMessageDiv = styled.div`
    width: 100%;
    height: 255px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    p{
        color: red;
    }
`;

export const RaritySettingsImageColectionItem = styled.div`
    width: 159px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 5px;

`;

export const RaritySettingsImageColectionItemInput = styled.div`
    width: 100%;
    height: auto;
    position: relative;

    input {
        padding: 10px 12px;
        width: 159px;
        height: 43px;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        margin-top: 10px;
        border: none;
        outline: none;
    }
`;

export const RaritySettingsImageColectionItemImage = styled.div`
    box-sizing: border-box;
    width: 159px;
    height: 189px;
    background: #252A30;
    border: 0.5px solid #2E343A;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width: 135px;
        height: 163.69px;
    }
`;

export const RaritySettingsUpdateButtonDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

export const RaritySettingsUpdateButton = styled.button`
    width: 496px;
    height: 59px;
    background: linear-gradient(90deg, #7B33EB 21.89%, rgba(9, 133, 246, 0.71) 98.16%);
    border-radius: 8px;
    font-family: 'Roboto Regular';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: #1F2328;
    border: none;
`;
export const RarityErrorMessage = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    p{
        font-family: 'Roboto Regular';
        font-style: normal;
        font-size: 13px;
        line-height: 20px;
        color: red;
        margin: 0;
    }
`;

export const RarityImageErrorMessage = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p{
        font-family: 'Roboto Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
        color: red;
        margin: 0;
    }
`;



export const PercentageContainer = styled.span`
    visibility:  ${(p) => p.leftCount > 15 ? 'visible' : 'hidden'};
    position: absolute;
    bottom: 10px;
    left: ${(p) => p.leftCount}px;

    color: ${(p) => p.disabled ? '#545454' : 'black'}
`;

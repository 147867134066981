import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { setIn } from 'final-form';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import * as actions from "../../redux/action";
import { callApi } from '../../services/api';

const Login = ({ setTitle, ...props }) => {
  const { login } = props;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [inputsColor, setInputsColor] = useState({
    emailColor: false,
    passwordColor: false,
  });

  useEffect(() => {
    setTitle('')
  }, [])

  const validationSchema = yup.object({
    email: yup.string().email("Enter valid email").required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const validateForm = schema => async values => {
    if (typeof schema === 'function')
      schema = schema();
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (e) {
      return e.inner.reduce((errors, error) => {
        return setIn(errors, error.path, error.message);
      }, {});
    }
  };

  const validate = validateForm(validationSchema);

  const onSubmit = (values) => {
    let loginUrl = 'user/login';
    callApi.post(loginUrl, values).then(({ data: response }) => {
      if (response.status) {
        login(response).then(res => {
          if (res) {
            navigate("/dashboard")
          }
        });
      }

      if (!response.status) {
        throw Error(response.statusText);
      }
    }).catch(({ response: { data: errors } }) => {
      setErrorMessage(errors.message);
      setInputsColor({
        emailColor: true,
        passwordColor: true,
      })

      return errors;
    });
  };

  const handleMouseDown = (key) => {
    setErrorMessage('');
    setInputsColor({
      ...inputsColor,
      [key]: false,
    })
  }

  return (
    <>
      <div className='nftm-forms-main'>
        <img className='nftm-forms-logo' src='/images/CodelessNFT_Logo_Light.png' alt='' />
        <div className='nftm-form-content'>
          <h2>Login to your Account</h2>
          <div className='divider-forms'></div>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field name={`email`}>
                    {({ input, meta }) => (
                      <div>
                        <input
                          {...input}
                          className={`collectionInputs ${inputsColor.emailColor ? 'errorMessageInput' : ''}`}
                          type="text"
                          placeholder={`Email`}
                          onMouseDown={() => handleMouseDown('emailColor')}
                        />
                        {meta.error && meta.touched && (
                          <div className="error-msg">
                            {meta.error}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name={`password`}>
                    {({ input, meta }) => (
                      <div>
                        <input
                          {...input}
                          className={`collectionInputs ${inputsColor.passwordColor ? 'errorMessageInput' : ''}`}
                          type="password"
                          placeholder={`Password`}
                          onMouseDown={() => handleMouseDown('passwordColor')}
                        />
                        {meta.error && meta.touched && (
                          <div className="error-msg">
                            {meta.error}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                  <input type="submit" className='login-btn' value="Login" />
                  <div className='login-error'>
                    {errorMessage ? <p>* {errorMessage}</p> : <></>}
                  </div>
                  <div className='login-footer'>
                    <div className='login-links'>
                      <div className='login-create-link'>
                        Don’t have an account? <br />
                        <Link to="/sign-up">Create an account</Link>
                      </div>
                    </div>
                    <Link to="/forgotPassword" className='login-forget'>Forgot Password?</Link>
                  </div>
                </form>
              )
            }}
          />
        </div>
      </div>
    </>
  )
};

const mapDispatchToProps = (dispatch) => ({
  login: (values) => dispatch(actions.login(values)),
  setTitle: (title) => dispatch(actions.setTitle(title)),
});

export default connect(null, mapDispatchToProps)(Login)

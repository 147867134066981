import React from 'react';
import { Navigate } from 'react-router-dom';
import LoginLayout from '../components/layout/LoginLayout';
import AccountLayout from '../components/layout/accountLayout'
import NFTLayout from '../components/layout/nftLayout';
import ContractsLayout from '../components/layout/contractsLayout';
import Login from "../components/login";
import SignUp from '../components/signUp';
import ForgotPassword from '../components/forgotPassword';
import Dashboard from '../components/dashboard';
import MyAccount from '../components/myAccount';
import TraitsBasic from '../components/traitsBasic';
import SmartContracts from '../components/smartContracts';
import ReportingLayout from '../components/layout/reportingLayout';
import ReportingDashboard from '../components/reportingDashboard';
import BillingPlan from '../components/BillingPlan';
import Payment from '../components/Payment/payment';
import Guard from '../Guard';

export const routes = [
    {
        path: '/',
        element: <LoginLayout />,
        children: [
            { path: 'login', element: <Login /> },
            { path: 'sign-up', element: <SignUp /> },
            { path: 'forgotPassword', element: <ForgotPassword /> },
            { path: '/', element: <Navigate to="/login" /> },
        ]
    },
    {
        path: '/',
        element: <Guard />,
        title: 'test',
        children: [
            { path: 'dashboard', element: <Dashboard /> },
            { path: 'billing', element: <BillingPlan /> },
        ]
    },
    {
        path: '/account',
        element: (<AccountLayout />, <Guard />),
        children: [
            { path: '', element: <MyAccount /> }
        ]
    },
    {
        path: '/nft',
        element: (<NFTLayout />, <Guard />),
        children: [
            { path: 'traits/:id', element: <TraitsBasic /> },
        ]
    },
    {
        path: '/payment',
        element: (<Payment />),
    },
    {
        path: '/',
        element: (<ContractsLayout />, <Guard />),
        children: [
            { path: 'smartcontracts', element: <SmartContracts /> },
        ]
    },
    {
        path: "/reporting",
        element: <ReportingLayout />,
        children: [
            { path: '', element: <ReportingDashboard /> },


        ]
    }
]




import styled from "styled-components";

export const PrevievCollectionDiv = styled.div`
    width: 100%;
    height: 100%;
    background:none;
`;

export const PrevievCollectionDivTitle = styled.div`
    width: 95%;
    height: 100px;
    border-bottom: 1px solid #2E343A;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 10px 0;
    margin: 0 auto;

    h2, p {
        font-family: 'Roboto Regular';
        font-style: normal;
        color: #CAD6EC;
    }

    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
    }

    p {
        width: 100%;
        height: 18px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin: 0;
    }
    a{
        color: #CAD6EC;
    }
`;

export const PrevievCollectionImageBox = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 20px 0;
    overflow-y: auto;
`;

export const PrevievCollectionImage = styled.div`

    box-sizing: border-box;
    width: 159px;
    height: 189px;
    background: #252A30;
    border: 0.5px solid #2E343A;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    position: relative;

    img{
        width: 135px;
        height: 163.69px;
        position: absolute;
        left: 0;
        top: 5px;
    }
`;
export const PrevievCollectionWatermark = styled.div`
        background-image: url(/images/Watermark.png) !important;
        background-size: cover;
        background-position: center;
        width: 135px;
        height: 163.69px;
        z-index: 1;
`;

export const PrevievCollectionButtonBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    overflow-y: auto;
`;


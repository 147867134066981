import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'notyf/notyf.min.css';
import {
  ErrorMessageDiv,
  PercentageContainer,
  PercentageContainerTrait,
  RarityErrorMessage,
  RarityImageErrorMessage,
  RaritySettingsDiv,
  RaritySettingsDivTitle,
  RaritySettingsImageColection,
  RaritySettingsImageColectionBox,
  RaritySettingsImageColectionItem,
  RaritySettingsImageColectionItemImage,
  RaritySettingsImageColectionItemInput,
  RaritySettingsUpdateButton,
  RaritySettingsUpdateButtonDiv,
  StyledCollectionInfoContainer,
  StyledCollectionInfoContainerButtons,
  StyledCollectionInfoContainerInputs,
} from './style';
import Select from '../Select';
import { connect } from 'react-redux';
import * as actions from "../../redux/action"
import { callApi } from '../../services/api';


function RaritySettings({
  show,
  setShow,
  traitsCard,
  collectionID,
  changeBackground,
  updateRaritySettings,
  errorMessage,
  setErrorMessage,
}) {
  const [isDisabled, setIsDesabled] = useState(true);
  const [traitRarity, setTraitRarity] = useState(null);
  const [imagesRarity, setImagesRarity] = useState([]);
  const [rarityErrors, setRarityErrors] = useState({});
  const [leftCount, setLeftCount] = useState({});
  const [traitPositonLeft, setTraitPositonLeft] = useState(42);

  const getOpition = (id) => {
    setErrorMessage('')
    changeBackground(id);
  }

  useEffect(() => {
    setImagesRarity([]);
    if (traitsCard.selectedTrait.distribution && traitsCard.selectedTrait.distribution === "custom") {
      setIsDesabled(false);
    } else setIsDesabled(true);

    if (traitsCard.selectedTrait.id) {
      setTraitRarity(traitsCard.selectedTrait.rarity || 100)
      setImagesRarity(traitsCard.selectedTrait.images)
    }
  }, [traitsCard.selectedTrait])

  const handleChangeImageRarity = (e, id) => {
    const { target: { value } } = e;
    if ((value.length <= 6 && parseInt(value, 10) <= 100 && parseInt(value, 10) >= 1) || !value) {
      setLeftCount({
        ...leftCount,
        [id]: value.length > 3 ? value.length * 10 + 2 : value.length * 10 + 15,
      })
      setImagesRarity((rarity) => (
        [...rarity?.map((item) => (
          item.id === id ? { ...item, rarity: value } : item
        ))]
      ))
    }

    setRarityErrors((prevErrors) => {
      delete prevErrors.rarityCount
      delete prevErrors[id]

      return { ...prevErrors }
    })
  }

  const handleChangeTraitRarity = (e, id) => {
    const { target: { value } } = e;
    if ((value.length <= 3 && parseInt(value, 10) <= 100 && parseInt(value, 10) >= 1) || !value) {
      setTraitRarity(value)
      setTraitPositonLeft(value.length * 10 + 15)
    }

    setRarityErrors((prevErrors) => {
      delete prevErrors.traitRarity

      return { ...prevErrors }
    })
  }

  const handleUpdateTraitRarity = () => {
    let rarityCount = 0;
    let items = {}

    const rarityConfigs = { hasError: false };

    imagesRarity.forEach(item => {
      if (item.rarity > 0 && item.rarity < 101) {
        rarityCount += parseInt(item.rarity, 10);
      } else {
        items[item.id] = `The rarity of the image must not be greater than 100 and less than 1`
        rarityConfigs.hasError = true
      }
    })

    setRarityErrors({ ...rarityErrors, ...items })

    if (traitRarity > 100 || traitRarity < 1) {
      rarityConfigs.hasError = true
      setRarityErrors({
        ...rarityErrors,
        traitRarity: "The rarity of the trait must not be greater than 100 and less than 1"
      })
      return
    } else delete rarityErrors["traitRarity"]

    if (rarityCount !== 100) {
      rarityConfigs.hasError = true
      setRarityErrors({
        ...rarityErrors,
        rarityCount: `The sum of image rarities must be equal to 100`
      })
      return
    } else delete rarityErrors["rarityCount"]

    if (!rarityConfigs.hasError) {
      const updateImagesRarity = imagesRarity.map((item) => {
        return {
          id: item.id,
          rarity: item.rarity,
        }
      })
      callApi.post(`collections/${collectionID}/trait/${traitsCard.selectedTrait.id}/rarity`, {
        rarity: traitRarity,
        distribution: isDisabled ? "evenly" : "custom",
        images: updateImagesRarity,
      },
      ).then((response) => {
        if (response.status === 200) {
          updateRaritySettings({
            collectionID: collectionID,
            traitID: traitsCard.selectedTrait.id,
            payload: {
              rarity: traitRarity,
              distribution: isDisabled ? "evenly" : "custom",
              images: updateImagesRarity,
            },
            updatedImages: imagesRarity,
          })
          setShow(!show);

        }
      }).catch((error) => {
        setErrorMessage(error.response.data.images[0]);
      });
    }
  }

  const handleRaritySettings = () => {
    let evenlyRarity = traitRarity / imagesRarity.length
    setImagesRarity((prev) => {
      prev.map((val) => (
        val.rarity = evenlyRarity.toFixed(2)
      ))
      return prev
    })
    setLeftCount({});
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(!show);
        }}
        className='rarity-settings-modal-main'
      >
        <Modal.Body>
          <RaritySettingsDiv>
            < RaritySettingsDivTitle>
              <h2>Rarity Settings</h2>
              <p>Adding rarity to certain traits and variations will help increase the value of certain NFTs. </p>
            </ RaritySettingsDivTitle>
            <StyledCollectionInfoContainer>
              <form onSubmit={(e) => e.preventDefault()}>
                <StyledCollectionInfoContainerInputs>
                  <div>
                    <label htmlFor="trite">TRAIT</label>
                    <Select
                      traitsCard={traitsCard}
                      getOpition={getOpition}
                    />
                  </div>
                  <div>
                    <label htmlFor="rarity">RARITY</label>
                    <input
                      className={`collectionInputs ${rarityErrors.traitRarity ? 'errorMessageInput' : ''}`}
                      value={traitRarity}
                      type="number"
                      id="rarity"
                      name="rarity"
                      max={100}
                      min={1}
                      step={1}
                      onChange={handleChangeTraitRarity}
                    />
                    <PercentageContainerTrait
                      disabled={isDisabled}
                      traitPositonLeft={traitPositonLeft}
                    >%</PercentageContainerTrait>
                  </div>
                </StyledCollectionInfoContainerInputs>
                <StyledCollectionInfoContainerButtons>
                  <h6>VARIATION DISTRIBUTION</h6>
                  <div>
                    <button
                      className={`rarity-button ${isDisabled ? 'disabled-rarity' : ''}`}
                      type='button'
                      onClick={() => {
                        setIsDesabled(true);
                        handleRaritySettings();
                      }}
                    >
                      EVENLY
                    </button>
                    <button
                      className={`rarity-button ${isDisabled ? '' : 'disabled-rarity'}`}
                      type='button'
                      onClick={() => {
                        setIsDesabled(false);
                        handleRaritySettings();
                      }}
                    >
                      CUSTOM
                    </button>
                  </div>
                </StyledCollectionInfoContainerButtons>
              </form>
            </StyledCollectionInfoContainer>
            < RaritySettingsImageColectionBox>
              <h6>VARIATIONS</h6>
              {errorMessage ? <ErrorMessageDiv>
                <p>* {errorMessage}</p>
              </ErrorMessageDiv> : <RaritySettingsImageColection>
                {imagesRarity?.map((item, index) => (
                  <RaritySettingsImageColectionItem key={item?.id}>
                    <RaritySettingsImageColectionItemImage>
                      <img src={item?.link} alt="" />
                    </RaritySettingsImageColectionItemImage>
                    <RaritySettingsImageColectionItemInput>
                      <input
                        className={`collectionInputs ${rarityErrors.rarityCount || rarityErrors[item.id] ? 'errorMessageInput' : ''}`}
                        value={item?.rarity}
                        type="number"
                        id={item.id}
                        min="1"
                        max="100"
                        lang="fr"
                        step="0.01"
                        disabled={isDisabled}
                        onChange={(e) => {
                          handleChangeImageRarity(e, item?.id);
                        }}
                      />
                      <PercentageContainer
                        disabled={isDisabled}
                        leftCount={leftCount.hasOwnProperty(item.id) ? leftCount[item.id] : 62}
                      >%</PercentageContainer>
                    </RaritySettingsImageColectionItemInput>
                    <RarityImageErrorMessage>
                      {rarityErrors[item.id] ? <p>{rarityErrors[item.id]}</p> : <></>}
                    </RarityImageErrorMessage>
                  </RaritySettingsImageColectionItem>
                ))}
              </RaritySettingsImageColection>}
            </RaritySettingsImageColectionBox>
            <RaritySettingsUpdateButtonDiv>
              <RaritySettingsUpdateButton onClick={handleUpdateTraitRarity}>UPDATE</RaritySettingsUpdateButton>
            </RaritySettingsUpdateButtonDiv>
            <RarityErrorMessage>{Object.keys(rarityErrors).length > 0 ?
              <>
                <p>{rarityErrors.traitRarity}</p>
                <p>{rarityErrors.rarityCount}</p>
              </>
              : <></>}</RarityErrorMessage>
          </RaritySettingsDiv>
        </Modal.Body>
      </Modal>
    </>
  )
}


const mapDispatchToProps = (dispatch) => ({
  updateRaritySettings: (data) => dispatch(actions.updateRaritySettings(data)),
})

export default connect(null, mapDispatchToProps)(RaritySettings);

import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../header';
import LeftMenu from '../leftMenu/reportingMenu';
// import ReportingMenu from '../rightMenu/reportingMenu';

const ReportingLayout = () => (
  <div className="wrapper">
    <Suspense fallback={"loading"}>
      <Header />
      <LeftMenu />
      {/* <ReportingMenu /> */}
      <Outlet />
    </Suspense>
  </div>
)

export default ReportingLayout;

import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ButtonsBox, DeleteModalContent } from './stayl';
import * as actions from "../../redux/action"

function ConfirmDeleteModal({
    showDeleteModal,
    setShowDeleteModal,
    deleteModalType,
    traitID,
    deleteTraitCardItem,
    itemIndex,
    collectionDataId,
    deleteData,
    imageId,
}) {
    const handleDeleteTrait = () => {
        deleteTraitCardItem({
            collectionID: collectionDataId,
            id: traitID,
            index: itemIndex,
        })
    }
    return (
        <>
            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(!showDeleteModal)}
                className='delete-modal-main'>
                <Modal.Body>
                    <DeleteModalContent>
                        <h2>Confirm Deletion</h2>
                        <p><img alt='' src="/images/confirm-mdl-icon.png" />
                            {
                                deleteModalType ?
                                    'Are you sure you would like to delete this trait? It will remove all files uploaded to this trait.'
                                    : 'Are you sure you would like to delete this file?'
                            }
                        </p>
                        <ButtonsBox>
                            <button onClick={() => setShowDeleteModal(false)}>CANCEL</button>
                            <button onClick={() => {
                                setShowDeleteModal(false);
                                deleteModalType ? handleDeleteTrait() : deleteData(imageId);
                            }}>DELETE</button>
                        </ButtonsBox>

                    </DeleteModalContent>
                </Modal.Body>
            </Modal>
        </>
    )
}
const mapDispatchToProps = (dispatch) => ({
    deleteTraitCardItem: (values) => dispatch(actions.deleteTraitCardItem(values)),
})

export default connect(null, mapDispatchToProps)(ConfirmDeleteModal);

import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { createCharacter } from '../../redux/action';

const CharacterModal = ({ showCharacterModal, setShowCharacterModal }) => {
  const [addValue, setAddValue] = useState('')
  const dispatch = useDispatch()

  const onSubmit = (value) => {
    const id = new Date().getTime().toString();
    dispatch(createCharacter({ id: id, char: addValue }))
    setAddValue("")
    setShowCharacterModal(!showCharacterModal)
  }
  const handleChange = (e) => {
    setAddValue(e.target.value)

  }
  return (
    <Modal show={showCharacterModal} onHide={() => setShowCharacterModal(!showCharacterModal)} className="character-modal-main">
      <Modal.Header >
        <Modal.Title>Character Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input type="text" placeholder="Add Character" onChange={handleChange} value={addValue} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onSubmit}>
          Submit
        </Button>

      </Modal.Footer>
    </Modal>
  )
}
export default CharacterModal;

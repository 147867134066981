import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd';
import { TraitsDashbordListItemButton, TraitsDashbordListItemName, TraitsLeftMenuListItem, TraitsPoligonLeftMenuListItem } from './style';
import TraitsBasicIcon from '../../asset/icons/basic-white-icon.svg';
import TraitsBasicIconSelected from '../../asset/icons/basic-black-icon.svg';
import TraitPolygon from "../../asset/icons/Polygon-trait.svg"

export const Card = ({
    position,
    name,
    index,
    moveCard,
    onDragEnd,
    selected,
    id,
    changeBackground,
    rarity,
    setImage,
}) => {
    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) {
                return
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            moveCard(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => {
            return { position, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref))

    return (
        <TraitsLeftMenuListItem
            ref={ref}
            onDragEnd={onDragEnd}
            style={{ opacity }}
            data-handler-order={handlerId}
            selectedItem={selected?.id === id && true}
            onClick={() => {
                changeBackground(id);
                setImage({
                    id: null,
                })
            }}
        >
            {selected?.id === id ?  <TraitsPoligonLeftMenuListItem src={TraitPolygon} alt='' /> : <></>}

            <TraitsDashbordListItemButton>
                <img src={selected?.id === id ? TraitsBasicIconSelected : TraitsBasicIcon} alt="" />
                <TraitsDashbordListItemName selectedItem={selected?.id === id && true}>{name}</TraitsDashbordListItemName>
                <span>{rarity || 100}%</span>
            </TraitsDashbordListItemButton>
        </TraitsLeftMenuListItem>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import 'react-rangeslider/lib/index.css'
import { connect } from 'react-redux';
import * as actions from "../../redux/action";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import ExportBarButton from "../exportBarButton";

const CARD_OPTIONS = {
  style: {
    base: {
      color: "#000000",
      fontWeight: 500,
      fontSize: "17px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#bfc4cc" },

    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#000000",

    },
  }
}

function CheckoutForm({
  confirmShowModal,
  setConfirmShowModal,
  products,
  paymentData,
  collectionId,
  promoCodeApply,
  promoCodeCancel,
  clearPromoCodeError,
  uniqueImages,
  checked,
  setChecked,
  setDataSuccsess,
  handleConfirmExport,
  disable
}) {
  const [promoCode, setPromoCode] = useState('');
  // const [paymentMode, setPaymentMode] = useState({
  //   creditMode: true,
  //   paypalMode: false
  // })
  const stripe = useStripe();
  const elements = useElements();
  const [productKey, setProductKey] = useState('free');

  const [validation, setValidation] = useState({
    cardNumber: {
      validation: false,
      message: ''
    },
    cardExpiry: {
      validation: false,
      message: ''
    },
    cardCvc: {
      validation: false,
      message: ''
    },
  });
  const [promoCodeError, setPromoCodeError] = useState({
    error: false,
    message: '',
  });

  const notyf = new Notyf();

  useEffect(() => {
    let key = Object.keys(products).find(key => products[key].total === uniqueImages);
    setProductKey(key)
  }, []);

  useEffect(() => {
    if (products[productKey]?.error) {
      setPromoCodeError({
        error: products[productKey]?.error,
        message: '* invalid promo code'
      })
    }
  }, [products[productKey]?.error])


  const handleInputChange = (key) => {
    if (validation[key]) {
      setValidation({
        ...validation,
        [key]: {
          validation: false,
          message: ''
        }
      })
    }
  }



  // const handlePayPeyPayment = async () => {
  //   let redirectUrl = window.location.origin + '/payment'
  //   let modalPopUp = window.open(`https://staging.paykickstart.com/checkout/paypal/create-payment-token?redirect=true&return_url=${redirectUrl}&cancel_url=${redirectUrl}`, 'popup', 'width=600,height=600');

  //   var timer = setInterval(function () {
  //     if (modalPopUp.closed) {
  //       let paypalToken = localStorage.getItem('__token')
  //       paymentData({
  //         collectionId: collectionId,
  //         values: {
  //           hash_id: productKey,
  //           payment_nonce: paypalToken,
  //         }
  //       })
  //       clearInterval(timer);
  //     }
  //   }, 500);
  // }

  const handelApply = () => {
    if (!!promoCode) {
      promoCodeApply({
        collectionId: collectionId,
        values: {
          hash_id: productKey,
          code: promoCode,
          promo_code: promoCode,
        },
      })

      return
    }
    setPromoCodeError({
      error: true,
      message: "* promo code required"
    })
  }

  const handleChangePromoCode = (e) => {
    setPromoCode(e.target.value);
    setPromoCodeError({
      error: false,
      message: '',
    });
    clearPromoCodeError(productKey);
  }

  const handelCancel = () => {
    if (!!products[productKey]?.promo_code) {
      setTimeout(() => {
        promoCodeCancel(productKey);
        setPromoCode('');
      }, 500)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const cardNumber = elements.getElement('cardNumber');
    const cardExpiry = elements.getElement('cardExpiry');
    const cardCvc = elements.getElement('cardCvc');

    const isValid = cardNumber._empty || cardExpiry._empty || cardCvc._empty
    const isInvalid = cardNumber._invalid || cardExpiry._invalid || cardCvc._invalid

    if (!isValid && !isInvalid) {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)
      })

      paymentData({
        collectionId: collectionId,
        values: {
          hash_id: productKey,
          // payment_intent_id: jsonResponse.intentObject.id,
          payment_method_id: paymentMethod.id,
          coupon_code: promoCode,
        }
      }).then((response) => {
        if (response.status === 200) {
          notyf.success(response.data.message);
          localStorage.removeItem('__token');
          setDataSuccsess(true);
          setConfirmShowModal(!confirmShowModal);
        }
      })
        .catch((error) => {
          notyf.success(error.response.data.message)
        });
      // var xhttp = new XMLHttpRequest();
      // xhttp.onreadystatechange = function () {
      //   if (this.readyState === 4 && this.status === 200) {
      //     var jsonResponse = JSON.parse(this.response);

      //   }
      // };
      // xhttp.open("POST", "https://staging.paykickstart.com/3ds/v2/generate-stripe-pi/497335", true);
      // xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      // xhttp.send("email=" + userData.email + "&first_name=" + userData.first_name + "&last_name="
      //   + userData.last_name + "&price=" + products[productKey]?.price + '&payment_method_id=' + paymentMethod.id);
    }
    setValidation({
      cardNumber: {
        validation: cardNumber._empty || cardNumber._invalid,
        message: cardNumber._empty || cardNumber._invalid ? (cardNumber._empty ? '* Card Number Requared' : '* Card Number invalid') : '',
      },
      cardExpiry: {
        validation: cardExpiry._empty || cardExpiry._invalid,
        message: cardExpiry._empty || cardExpiry._invalid ? (cardNumber._empty ? '* Card Expiry Requared' : '* Card Expiry invalid') : '',
      },
      cardCvc: {
        validation: cardCvc._empty || cardCvc._invalid,
        message: cardCvc._empty || cardCvc._invalid ? (cardNumber._empty ? '* Card Cvc Requared' : '* Card Cvc invalid') : '',
      },
    })
  }

  return (
    <div className='new-collection-modal-content '>

      <h2>Confirm Order</h2>
      <div className='modal-devider'></div>
      <form className="payment-body">
        {
          uniqueImages >= 1000
            ? <div className='payment-main payment-show'>
              {/* <div className='row'>
                <div className='col-12'>
                  <label>SELECT PAYMENT METHOD</label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12' onClick={!confirmOrder.isDisabled ? () => setPaymentMode({ creditMode: true }) : null} >
                  <div className={paymentMode?.creditMode && !confirmOrder.isDisabled ? 'payment-select-card card-active' : 'payment-select-card'}>
                    <img src='/images/visa-icon.png' alt='' />
                    <img src='/images/master-card-icon.png' alt='' />
                    <img src='/images/Discover.png' alt='' />
                    <img src='/images/amex-icon.png' alt='' />
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12' onClick={() => { setPaymentMode({ paypalMode: true }); handlePayPeyPayment() }}>
                  <div className={paymentMode?.paypalMode ? 'payment-select-card card-active' : 'payment-select-card'}>
                    <img src='/images/PayPal-Logo.png' alt='' />
                  </div>
                </div>
              </div> */}
              <div>
                <label className='input-lable'>CARD NUMBER</label>
                <div className={`card-number ${validation.cardNumber.validation ? 'error-payment-input' : ''}`}>
                  <CardNumberElement
                    options={CARD_OPTIONS}
                    readOnly={true}
                    onChange={() => handleInputChange('cardNumber')}
                  />
                </div>
                <p className="error-payment-message"> {validation.cardNumber.message}</p>
              </div>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div>
                    <label className='input-lable'>EXPIRATION</label>
                    <div className={`collectionDescription-input ${validation.cardExpiry.validation ? 'error-payment-input' : ''}`}>
                      <CardExpiryElement
                        options={CARD_OPTIONS}
                        onChange={() => handleInputChange('cardExpiry')}
                      />
                    </div>
                    <p className="error-payment-message"> {validation.cardExpiry.message}</p>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div>
                    <label className='input-lable'>CVC</label>
                    <div className={`collectionDescription-input ${validation.cardCvc.validation ? 'error-payment-input' : 'payment-input'}`}>
                      <CardCvcElement
                        options={CARD_OPTIONS}
                        onChange={() => handleInputChange('cardCvc')}
                      />
                    </div>
                    <p className="error-payment-message"> {validation.cardCvc.message}</p>
                  </div>
                </div>
              </div>
              <div className="promo-code-box">
                <label className='input-lable'>PROMO CODE</label>
                <input
                  name="collectionDescription"
                  className={promoCodeError.error ? 'error-input' : ""}
                  type="text"
                  placeholder="90210"
                  value={promoCode}
                  maxLength={20}
                  disabled={products[productKey]?.disabled || false}
                  onChange={handleChangePromoCode}
                />
                {
                  !!products[productKey]?.old_price
                    ? <button type='button' className='cancel-btn' onClick={handelCancel}>CANCEL</button>
                    : <button type='button' className='apply-btn' onClick={handelApply}>APPLY</button>
                }
              </div>
              <p className="error-payment-message"> {promoCodeError.message}</p>
            </div> : <></>
        }


        <div className='new-collection-your-price'>
          Your Price : <span><p>$</p>{products[productKey]?.price}</span>
          <p className="old_price">{!!products[productKey]?.old_price ? '$' + products[productKey]?.old_price : ''}</p>
        </div>
        <div className="new-collection-check">
          <div className="form-group form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <label className="form-check-label">
              By clicking the checkbox, I acknowledge that my payment method will be charged and my collection will be generated.   You will not be able to modify it after purchase.
            </label>
          </div>
        </div>
      </form >
      <ExportBarButton
        checked={checked}
        uniqueImages={uniqueImages}
        handleSubmit={handleSubmit}
        setConfirmShowModal={setConfirmShowModal}
        confirmShowModal={confirmShowModal}
        handleConfirmExport={handleConfirmExport}
        disable={disable}
        collectionId={collectionId}
      />
    </div >
  )
}

const mapStateToProps = (state) => ({
  userData: state?.auth?.userData,
  products: state?.auth?.products,
  showProgress: state?.collectionReducer?.showProgress,
})

const mapDispatchToProps = (dispatch) => ({
  paymentData: (data) => dispatch(actions.paymentData(data)),
  promoCodeApply: (data) => dispatch(actions.promoCodeApply(data)),
  promoCodeCancel: (value) => dispatch(actions.promoCodeCancel(value)),
  clearPromoCodeError: (value) => dispatch(actions.clearPromoCodeError(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)

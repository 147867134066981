export const loginType = {
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    USER_DATA: "USER_DATA",
    UPDATE_USER_DATA: "UPDATE_USER_DATA",
    PROMO_CODE_DATA: "PROMO_CODE_DATA",
    PROMO_CODE_DATA_ERROR: "PROMO_CODE_DATA_ERROR",
    PROMO_CODE_DATA_CANCEL: "PROMO_CODE_DATA_CANCEL",
    CLEAR_PROMO_CODE_ERROR: "CLEAR_PROMO_CODE_ERROR",
    GET_ACCOUNT_DATA: "GET_ACCOUNT_DATA",
}

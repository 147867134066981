import React, { useState } from 'react';
import DeployModalFile from '../deployModalFile';
import EmbedCodeModal from '../embedCodeModal';
import MetaDataModal from '../metaDataModal';

function PublishColection() {
    const [process, setProcess] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [isWallet, setIsWallet] = useState(false)
    const [isPublish, setIsPublish] = useState(false)
    const [show, setShow] = useState(false);
    const [showEmbed, setShowEmbed] = useState(false);
    const [showMetaData, setShowMetaData] = useState(false);


    const ClickOnReady = () => {
        setProcess(true)
        if (!process) {
            setTimeout(() => {
                setProcess(false)
            }, 1000)
            setTimeout(() => {
                setisLoading(true)

            }, 1000)
        }
    }

    return (
        <>
            <div className='PublishCollection-main selectContacts-cont'>
                <h3 className=''>Follow the steps below to successfully deploy and publish your collection for minting.</h3>
                <div className='page-devider'></div>
                <div className='PublishCollection-lists-cont'>
                    <div className='PublishCollection-list'>
                        <h4>Upload to IPFS</h4>
                        <div className='PublishCollection-btns'>
                            <button className='btn-disable' onClick={() => setShowMetaData(true)}>REVIEW METADATA</button>
                            {!process ?
                                !isLoading ? <button className='btn-done' onClick={ClickOnReady}>UPLOAD COLLETION FILES</button> : ""
                                :
                                <button className='btn-process' >Processing...</button>
                            }
                            {isLoading &&
                                <button className='btn-complete'>success</button>
                            }
                        </div>
                    </div>
                    <div className='PublishCollection-list'>
                        <h4>Connect MetaMask Wallet</h4>
                        <div className='PublishCollection-btns'>
                            {!isWallet ?
                                <button className='btn-done' onClick={() => setIsWallet(true)} >CONNECT</button>
                                :
                                <>
                                    <button className='btn-complete'>1aXe0Xbl1ewd</button>
                                    <button className='btn-unlink' onClick={() => setIsWallet(false)}>unlink wallet</button>
                                </>}
                        </div>
                    </div>
                    <div className='PublishCollection-list'>
                        <h4>Create a Smart Contract</h4>
                        <div className='PublishCollection-btns'>
                            <button className='btn-disable'>REVIEW CONTRACT</button>
                            <button className='btn-done' >DEPLOY (TESTNET)</button>
                            <button className='btn-done' onClick={() => setShow(true)} >DEPLOY (MAINNET)</button>
                        </div>
                    </div>
                    <div className='PublishCollection-list'>
                        <h4>Add Mint Button to Website</h4>
                        <div className='PublishCollection-btns'>
                            <button className='btn-done' onClick={() => setShowEmbed(true)}>GET EMBED CODE</button>
                        </div>
                    </div>
                    <div className='PublishCollection-list'>
                        <h4>Publish to OpenSea</h4>
                        <div className='PublishCollection-btns'>
                            {!isPublish ?
                                <button className='btn-done' onClick={() => setIsPublish(true)}>PUBLISH</button>
                                :
                                <button className='btn-complete'>published</button>
                            }
                        </div>
                    </div>
                    <div className='PublishCollection-list'>
                        <h4>Publish to Mintable</h4>
                        <div className='PublishCollection-btns'>
                            <span>(Coming Soon)</span>
                        </div>
                    </div>
                    <div className='PublishCollection-list'>
                        <h4>Publish to Rarible</h4>
                        <div className='PublishCollection-btns'>
                            <span>(Coming Soon)</span>
                        </div>
                    </div>
                </div>
                <DeployModalFile show={show} setShow={setShow} />
                <MetaDataModal showMetaData={showMetaData} setShowMetaData={setShowMetaData} />
                <EmbedCodeModal showEmbed={showEmbed} setShowEmbed={setShowEmbed} />
            </div>
        </>
    )
}

export default PublishColection




import styled from "styled-components";

export const ExportStatusCollection = styled.button`
    width: 100%;
    height: 20px;
    font-family: "Roboto Bold";
    font-size: 15px;
    line-height: 20px;
    color: #cad6ec;
    transition: .3s;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CollectionExportButton = styled.button`
    background:${props => props.disable
        ? 'linear-gradient(90deg, #353535cc 0%, rgba(26, 140, 255, 0) 177.69%)'
        : 'linear-gradient(90deg, #7b33eb 21.89%, rgba(9, 133, 246, 0.71) 98.16%)'};
    border-radius: 8px;
    border: none;
    font-family: "Roboto Bold";
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.disable ? 'rgba(255, 255, 255, 0.544)' : '#1f2328'};
    padding: 6px;
    width: 514px;
    height: 59px;
`;


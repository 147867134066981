import React, { useEffect } from 'react'
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from "../../redux/action";

function ForgotPassword({
    setTitle
}) {
    const onSubmit = () => { }

    useEffect(() => {
        setTitle('Forgot Passwo');
    }, [])

    return (
        <div className='nftm-forms-main'>
            <img className='nftm-forms-logo' src='/images/CodelessNFT_Logo_Light.png' alt='' />
            <div className='nftm-form-content forget-cont'>
                <h2>Password Reset</h2>
                <span>Enter the email address associated with your CodelessNFT account and we will send you an email to reset your password.</span>
                <div className='divider-forms'></div>
                <Form
                    onSubmit={onSubmit}
                    className=""
                    render={({ handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Field name={`email`}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label htmlFor="Email">
                                                <input {...input} type="text" placeholder='Email Address' />
                                                {meta.error && meta.touched && (
                                                    <div className="error-msg">
                                                        {meta.error}
                                                    </div>
                                                )}
                                            </label>
                                            <input type="submit" className=' login-btn' value="SEND INSTRUCTIONS" />
                                        </div>
                                    )}
                                </Field>
                            </form>
                        )
                    }}
                />
                <div className='forget-footer'>
                    <div className='login-links'>
                        <div className='login-create-link'>
                            It may take up to one minute to receive your code. <br />
                            Haven’t received it? <button className='resend-code-btn'><img src="/images/resend-icon.png" alt="" /> Resend code</button>
                        </div>
                    </div>
                    <Link to="/login" className='login-forget'>Log in here</Link>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setTitle: (title) => dispatch(actions.setTitle(title)),
});

export default connect(null, mapDispatchToProps)(ForgotPassword)


import React from 'react';
import SelectBlockchain from '../selectBlockchain';
import MentingOptions from '../mentingOptions';
import PublishColection from '../publishColection';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../../redux/action"
import { useNavigate } from "react-router-dom"

function SmartContracts() {
  const state = useSelector(state => state.smartContractsReducer.step)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const renderNextForm = () => {
    dispatch(actions.smartContracts())
  }

  const renderPrevForm = () => {
    dispatch(actions.prevSmartContract())
  }

  return (
    <>
      <div className="content-main left-menu smart-left-content">
        {state === 0 && <SelectBlockchain />}
        {/* {state === 1 && <CollectionDetails />} */}
        {state === 1 && <MentingOptions />}
        {state === 2 && <PublishColection />}
        <div className="d-flex smart-butoons">
          {state === 0 ||
            <button className='back-arrow-btn' onClick={() => renderPrevForm()}> <img src="/images/left-arrow.png" alt="" /></button>
          }
          {state === 2 ||
            <button className='continue-btn' onClick={() => renderNextForm()}>CONTINUE <img src="/images/right-arrow-white.png" alt="" /></button>
          }
          {state === 2 &&
            <button onClick={() => navigate("/reporting")} className='back-dash-btn'>BACK TO DASHBOARD</button>
          }
        </div>
      </div>
    </>
  )
}

export default SmartContracts
import { combineReducers } from "redux"
import auth from "../reducer/auth"
import collectionReducer from "../reducer/collections"
import smartContractsReducer from "./smartContracts"
import config from "./config"

const rootReducer = combineReducers({
  auth,
  collectionReducer,
  smartContractsReducer,
  config
})

export default rootReducer;

import React, { useEffect, useMemo, useState } from 'react'; /*eslint-disable */
import { InputGroup, FormControl, Card, Dropdown, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Pagination from '../Pagination/Pagination';
import AddCollection from '../createCollection';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Confirm from '../common/Modal/Confirm/Confirm';
import { callApi } from '../../services/api';;
import debounce from 'lodash.debounce';
import { StyledPaginationContainer, StyledAddNewCollectionBox, StyledDashboardImageUpload, StyledDashboardImageCollection } from './styles';
import exportIcon from '../../asset/icons/Group.svg';
import 'notyf/notyf.min.css';
import * as actions from "../../redux/action"
import { useExport } from '../../utils/useExport';
import DefaultImage from '../../asset/icons/defaultImage.svg'


function Dashboard({
  getCollectionData,
  getPaginationData,
  collectionData,
  deleteCollection,
  clearTraitsData,
  getUnactiveCollectionData,
  clearImagesCollection,
  setTitle,
  showProgress,
}) {
  const [addCollection, setAddCollection] = useState(false);
  const [search, setSearch] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [collectionEditData, setCollectionEditData] = useState({});
  const [pagination, setPagination] = useState(1);
  const [isActiv, setIsActiv] = useState(true);

  useExport('');

  const navigate = useNavigate();

  useEffect(() => {
    setTitle('Dashboard')
    getCollectionData();
    clearTraitsData();
    clearImagesCollection();
  }, [])

  const EditCollectionData = (item) => {
    setCollectionEditData(item)
    setAddCollection(true)
  }

  const deleteCollectionData = (id) => {
    deleteCollection(id)
      .then(() => {
        getPaginationData({
          pagination: pagination,
          dataLength: collectionData.data?.length,
        });
        setAddCollection(false)
      })
  }

  useEffect(() => {
    if (search.length > 0) {
      if (!searchData.length || searchData.length <= 12) {
        setIsActiv(false)
      } else setIsActiv(true)
    } else if (collectionData.data && (!collectionData.data.length || searchData.length <= 12)) {
      setIsActiv(false)
    } else setIsActiv(true)
  }, [searchData, collectionData, search])


  const getSearch = (value) => {
    callApi.get(`dashboard/?q=${value}`).then((response) => {
      if (response.status === 200) {
        setSearchData(response.data.collections.data);
      }
    }).catch((error) => {
      setErrorMessage(true)

      return error;
    });

  }

  const getPagination = ({ selected }) => {
    getPaginationData(
      {
        pagination: selected + 1,
        dataLength: null,
      })
      .then(() => {
        setPagination(selected + 1);
      });

  }

  const debouncedResults = useMemo(() => {
    return debounce(getSearch, 400);
  }, []);

  useEffect(() => {
    debouncedResults(search);

    return () => {
      debouncedResults.cancel();
    }
  }, [debouncedResults, search, collectionData]);

  return (
    <>
      <div className="content-main-black ">
        <div className='dashboard-cont'>
          <div className='dashboard-search'>
            <form className='search-dash'>
              <div className='row'>
                <div className='col-lg-5 col-md-6 col-sm-12 dash-input ' htmlFor="FirstName">
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Search collection"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="search-icon">
                      <img src='/images/search.png' alt="" />

                    </div>
                  </InputGroup>
                </div>
                <div className='col-lg-2 col-md-2 col-sm-12 dash-input dash-active-select' htmlFor="Active">
                  {/* <img src={selectIcon} alt="" className='select-icon'/> */}
                  <Form.Select size="lg"
                    onChange={(e) => getUnactiveCollectionData(e.target.value)}
                  >
                    <option value="true">Active</option>
                    <option value="false">Archived</option>
                  </Form.Select>
                </div>
              </div>
            </form>
            <StyledAddNewCollectionBox className='col-lg-5 col-md-6 col-sm-12' onClick={() => setAddCollection(true)}>
              <StyledDashboardImageUpload>
                <img src="/images/plus-icon.png" alt="" />
                <span>New Collection</span>
              </StyledDashboardImageUpload>
            </StyledAddNewCollectionBox>
          </div>
          <div className='dashboard-upload'>
            <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
              <div className='row'>
                {collectionData.data?.length ? ((searchData && !!search?.length) ? searchData : collectionData.data)
                  .map((item) => (
                    <div className='col-lg-1 col-md-4 col-sm-6 pb-3 dashboard-cards' key={item.id}>
                      <Card>
                        <Card.Header className='card-head' onClick={() => navigate(`/nft/traits/${item.id}`)} >
                          <StyledDashboardImageCollection
                            logo={!!item.logo
                              ? item.logo
                              : DefaultImage}
                            sizeLogo={!!item.logo}
                          ></StyledDashboardImageCollection>
                        </Card.Header>
                        <Card.Body>
                          <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12 dash-upload-left'>
                              <Card.Title>{item.name}</Card.Title>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 dash-upload-right'>
                              {/* <button className='card-btns' onClick={() => navigate('/smartcontracts')}>
                                <img src="/images/circle-icon.png" alt="" />
                              </button> */}
                              {
                                item.preview && !!item.export ? <>
                                  {
                                    !item.archive ? <></>
                                      : <button className="card-btns" onClick={() => window.open(item.archive, "_self")}>
                                        <a href={item?.ImageUrl} download={item?.ImageUrl}>
                                          <img src={exportIcon} alt="" />
                                        </a>
                                      </button>
                                  }
                                </> : <></>
                              }
                              <Dropdown>
                                <Dropdown.Toggle variant="success" className='card-btns'>
                                  <img src="/images/doted.png" alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item disabled={!!showProgress[item.id]?.show} className={` ${!!showProgress[item.id]?.show ? 'elementDisabled' : ''}`} onClick={() => EditCollectionData(item)} ><img src="/images/edit-icon.png" alt="" /> Edit Collection</Dropdown.Item>
                                  {/* <Dropdown.Item onClick={() => onManageContract()} ><img src="/images/download-icon-color.png" alt="" />Manage Contract</Dropdown.Item> */}
                                  <Confirm
                                    content={
                                      <div className='confirm-modal-content-div'>
                                        <h1>Confirm Archive</h1>
                                        <div className='confirm-modal-content-text'>
                                          <img alt='' src="/images/confirm-mdl-icon.png" className='confirm-mdl-icon' />
                                          <span className='confirm-mdl-content'>Are you sure you would like to archive this collection?</span>
                                        </div>
                                      </div>
                                    }
                                    button={<Dropdown.Item disabled={!!showProgress[item.id]?.show} className={`archiveCollection ${!!showProgress[item.id]?.show ? 'elementDisabled' : ''}`}><img src="/images/archive-icon.png" alt="" /> Archive Collection</Dropdown.Item>}
                                    confirmButton={<Button variant="primary" className='export-modal-button' type="submit" >ARCHIVE</Button>}
                                    cancelButton={<Button variant="primary" className='export-modal-button' type="submit" >Cancel</Button>}
                                    onConfirm={() => deleteCollectionData(item.id)}

                                  />
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )) : null}
              </div>
            </div>
          </div>
        </div>
        {
          collectionData.meta && (
            <StyledPaginationContainer>
              {
                isActiv
                  ? <Pagination
                    currentPage={collectionData.meta.current_page}
                    totalCount={collectionData.meta.total}
                    forcePage={collectionData.meta.current_page - 1}
                    pageCount={collectionData.meta.last_page}
                    onPageChange={getPagination}
                  /> : <></>
              }

            </StyledPaginationContainer>
          )}
      </div>
      <AddCollection
        addCollection={addCollection}
        setAddCollection={setAddCollection}
        collectionEditData={collectionEditData}
        setCollectionEditData={setCollectionEditData}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  collectionData: state?.collectionReducer?.collectionData,
  showProgress: state?.collectionReducer?.showProgress,
})

const mapDispatchToProps = (dispatch) => ({
  getCollectionData: () => dispatch(actions.getCollectionData()),
  getUnactiveCollectionData: (value) => dispatch(actions.getUnactiveCollectionData(value)),
  clearTraitsData: () => dispatch(actions.clearTraitsData()),
  clearImagesCollection: () => dispatch(actions.clearImagesCollection()),
  deleteCollection: (value) => dispatch(actions.deleteCollection(value)),
  getPaginationData: (value) => dispatch(actions.getPaginationData(value)),
  setTitle: (title) => dispatch(actions.setTitle(title)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

import React, { useState, useRef, useEffect } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { ChromePicker } from 'react-color'
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
function EmbedCodeModal({ showEmbed, setShowEmbed }) {
    const [show, setShow] = useState({ bgColorSpace: false, btnColorSpace: false })
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const colorPickerRef = useRef(null);
    const [btnColors, setBtnColors] = useState({ hex: "" });
    const [bgColors, setBgColors] = useState({ bgHex: "" })

    const BgColorPicker = e => {
        const newBgColor = {
            bgHex: e.hex,
            // bgRgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")"
        }
        setBgColors(newBgColor)
        setTimeout(() => {
            setShow({ bgColorSpace: false })
        }, 1000)
    }

    const BtnColorPicker = e => {
        const newBtnColor = {
            hex: e.hex,
            // rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")"
        };
        setBtnColors(newBtnColor)
        // setTimeout(() => {
        //     setShow({ btnColorSpace: false })
        // }, 1000)
    };

    const handleClickOutside = (e) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(e.target)) {
            setShow({ btnColorSpace: false });
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);
    return (
        <>
            <Modal show={showEmbed} onHide={() => { setShowEmbed(!showEmbed); setTimeout(() => { localStorage.removeItem('testnet') }, 1000) }} className='delete-modal-main smart-deploy-modal embed-modal-main'>
                <Modal.Body>
                    <div className='delete-modal-content'>
                        <h2>Embed Mint Button</h2>
                        <div className='smart-modal-devider'></div>
                        <div className='embed-modal-cont'>
                            <Tabs defaultActiveKey="rawCode" id="uncontrolled-tab-example" className="">
                                <Tab eventKey="rawCode" title="RAW CODE">

                                    <p><b>Step 1:</b> Add the following javascript code at the end of the {"</head>"} tag on the page you are adding your mint button to.</p>
                                    <div className='smart-deploy-modal-cont'>
                                        <p>
                                            {'<script type="text/javascript" src="https://app.paykickstart.com/tracking-script"></script>'}
                                        </p>
                                    </div>
                                    <button className='btn-copy'>COPY</button>
                                    <p><b>Step 2:</b> Add the following javascript code where you would like the button to display on your page.</p>
                                    <div className='smart-deploy-modal-cont'>
                                        <p>
                                            {'<script type="text/javascript" src="https://app.paykickstart.com/tracking-script"></script>'}
                                        </p>
                                    </div>
                                    <button className='btn-copy'>COPY</button>
                                </Tab>
                                <Tab eventKey="styled" title="STYLED">
                                    <p><b>Step 1:</b> Style your minting area.</p>
                                    <div className='background-and-button-main'>
                                        <div className='background-color-btn'>
                                            <button
                                                onClick={() => { setShow({ bgColorSpace: true, btnColorSpace: false }) }}
                                                style={{
                                                    backgroundColor: bgColors?.bgHex ?
                                                        bgColors?.bgHex : "rgba(43, 50, 61, 0.7)"
                                                }}>

                                            </button>
                                            <span>BACKGROUND</span>
                                        </div>
                                        <div className='button-color-btn'>
                                            <button
                                                onClick={() => { setShow({ bgColorSpace: false, btnColorSpace: true }) }}
                                                style={{
                                                    backgroundColor: btnColors?.hex ?
                                                        btnColors?.hex : "rgba(82, 96, 230, 0.7)"
                                                }}>
                                            </button>
                                            <span>BUTTON</span>
                                        </div>
                                    </div>
                                    {show.bgColorSpace && (
                                        <div ref={colorPickerRef}>
                                            <ChromePicker
                                                color={bgColors !== null && bgColors.bgHex}
                                                onChange={e => BgColorPicker(e)}
                                                disableAlpha
                                                renderers={false}
                                            />
                                        </div>
                                    )}
                                    {show.btnColorSpace && (
                                        <div ref={colorPickerRef}>
                                            <ChromePicker
                                                color={btnColors !== null && btnColors.hex}
                                                onChange={e => BtnColorPicker(e)}
                                                disableAlpha
                                                renderers={false}
                                            />
                                        </div>

                                    )}

                                    <div className='embed-modal-editor'
                                        style={{
                                            backgroundColor: bgColors?.bgHex ?
                                                bgColors?.bgHex : "rgba(43, 50, 61, 0.6)"
                                        }}>

                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                            toolbar={{
                                                options: ["inline"],
                                                inline: {
                                                    inDropdown: false,
                                                    className: "test",
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                    options: ["bold", "italic", "underline"],
                                                    bold: { className: "test", style: { color: "red" } },
                                                    italic: { className: undefined },
                                                    underline: { className: undefined },
                                                },

                                            }}
                                            placeholder="JOIN OUR CLUB"
                                        />
                                        <p>To get started, please connect your wallet.</p>
                                        <button className="btn-connect"
                                            style={{ backgroundColor: btnColors?.hex ? btnColors?.hex : "rgba(82, 96, 230, 0.7)" }}>
                                            Connect Wallet <img src="/images/connect-wallet-icon.png" alt="" /></button>
                                    </div>

                                    <p style={{ color: 'white' }}><b>Step 2: </b> Add the following javascript code at the end of the {'</head>'} tag on the page you are adding your mint button to.</p>
                                    <button className='btn-copy'>COPY</button>
                                    <div className='smart-deploy-modal-cont'>
                                        <p>
                                            {'<script type="text/javascript" src="https://app.paykickstart.com/tracking-script"></script>'}
                                        </p>
                                    </div>
                                    <p style={{ color: 'white' }}><b>Step 3:</b> Add the following javascript code where you would like the button to display on your page.</p>
                                    <button className='btn-copy'>COPY</button>
                                    <div className='smart-deploy-modal-cont'>
                                        <p>
                                            {'<script type="text/javascript" src="https://app.paykickstart.com/tracking-script"></script>'}
                                        </p>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default EmbedCodeModal
import React from 'react';
import { Modal } from 'react-bootstrap';

function MetaDataModal({ showMetaData, setShowMetaData }) {

  return (
    <>
      <Modal show={showMetaData} onHide={() => { setShowMetaData(!showMetaData) }} className='delete-modal-main review-metadata-modal'>
        <Modal.Body>
          <div className='delete-modal-content '>
            <h2>REVIEW METADATA</h2>
            <p>Please review the below metadata to ensure it is correct before uploading to IPFS.</p>
            <div className='smart-modal-devider'></div>

            <div className='metadata-script-cont-mdl'>
              <p>
                {'<script type="text/javascript" src="https://app.paykickstart.com/tracking-script"></script>'}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MetaDataModal
import { collectionDataType } from "../../constants/collections"
import { callApi } from "../../../services/api";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import { loginType } from "../../constants/auth";

const notyf = new Notyf();

export const getCollectionData = () => {
  return async (dispatch) => {
    callApi.get('dashboard')
      .then(({ data: response }) => {
        dispatch({
          type: collectionDataType.GET_COLLECTION_DATA,
          payload: response.collections,
        });

        dispatch({
          type: loginType.GET_ACCOUNT_DATA,
          payload: response.myAccountData,
        });
      })
      .catch(({ response }) => {
        if (response && response.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
        } else {
          console.log(response);
        }
      });
  }
}
export const getUnactiveCollectionData = (value) => {
  return async (dispatch) => {
    await callApi.get(`dashboard?active=${value}`)
      .then(({ data: response }) => {
        dispatch({
          type: collectionDataType.GET_UNACTIVE_COLLECTION_DATA,
          payload: response.collections,
        });
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }
}
export const getPaginationData = (value) => {
  const pagination = value.dataLength === 1 && value.pagination !== 1
    ? value.pagination - 1 : value.pagination;
  return async (dispatch) => {
    callApi.get(pagination === 1 ? 'dashboard' : `dashboard/?page=${pagination}`)
      .then(({ data: { collections }, status }) => {
        if (status) {
          dispatch({
            type: collectionDataType.GET_COLLECTION_DATA,
            payload: collections,
          });
        }
      })
      .catch((error) => {
        return error;
      });
  }

}

export const createCollection = (values) => async (dispatch) => {
  return await callApi.post('collections/create', values)
}

export const editCollection = (values) => {
  return async (dispatch) => {
    await callApi.post(`collections/${values.id}/update`, values.data)
      .then(({ data: response }) => {
        if (response.status) {
          notyf.success(response.message)
          dispatch({
            type: collectionDataType.EDIT_COLLECTION_DATA,
            payload: {
              id: values.id,
              ...values.data,
            },
          })
        }
      })
  }
}


export const updateImage = (data) => {
  return async (dispatch) => {
    callApi.post(`collections/${data.updateImageID}/logo`, data.image)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: collectionDataType.UPDATE_IMAGE_DATA,
            payload: {
              id: data.updateImageID,
              image: response.data.url,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}

export const exportCollection = (data) => async (dispatch) => {
  return await callApi.get(`collections/${data.collectionId}/export`).then((response) => {
    if (response.status === 200) {
      if (!!response.data.url) {
        notyf.success(response.data.message);
        window.open(response.data.url, "_self");
      }
      dispatch({
        type: collectionDataType.EXPORT_DATA,
        payload: {
          url: response.data.url,
          key: data.key,
          collectionId: data.collectionId,
        },
      })
    }
  })
}

export const exportCollectionStatus = (id) => async (dispatch) => {
  return await callApi.get(`collections/${id}/export-status`).then((response) => {
    if (response.status === 200) {
      dispatch({
        type: collectionDataType.EXPORT_STATUS,
        payload: {
          export_status: response.data,
          collectionIdSatus: id,
        },
      });
    }
  })
}

export const changeTimeoutId = (values) => async (dispatch) => {
  dispatch({
    type: collectionDataType.CHANGE_TIMEOUT_ID,
    payload: values,
  });
}

export const deleteExportStatusData = (id) => async (dispatch) => {
  dispatch({
    type: collectionDataType.DELETE_EXPORT_STATUS_DATA,
    payload: id,
  });
}

export const deleteCollection = (id) => async () => {
  return await callApi.get(`collections/${id}/archive`)
};

export const createImageCollection = (data) => {
  const { dataId: { collectionID, traitID }, values } = data;

  const imageData = new FormData();
  imageData.append('image', values);

  return async (dispatch) => {
    return await callApi.post(`collections/${collectionID}/trait/${traitID}/image/upload`, imageData)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: collectionDataType.CREATE_IMAGE_COLLECTION,
            payload: {
              traitID: traitID,
              image: response.data.image,
              imagesRarity: response.data.images_rarity,
            },
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: collectionDataType.NOT_VALID_IMAGES,
          payload: {
            notValidId: traitID,
            not_valid_images: error.response.data,
          }

        })
      });
  }
}

export const getPreviewCollectionImages = (data) => {
  return async (dispatch) => {
    await callApi.get(`collections/${data.collectionID}/preview?page=${data.page}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: collectionDataType.GET_PREVIEW_COLLECTION_IMAGES,
            payload: {
              images: response.data.images,
              empty: response.data.images.length < 20
            }
          },
          );
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}

export const deleteImageCollection = (data) => {
  return async () => {
    await callApi.get(`collections/${data.collectionID}/trait/${data.traitID}/images/${data.imageID}/delete`)
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}

export const addUserData = () => {
  return async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"))
      if (userData) {
      }
    }
    catch (err) {
    }
  }
}

export const createChipData = (values) => {
  return async (dispatch) => {
    try {
      const chipData = JSON.parse(localStorage.getItem("chipData")) || [];
      chipData.push(values);
      if (chipData) {
        localStorage.setItem("chipData", JSON.stringify(chipData))
        dispatch({
          type: collectionDataType.CREATE_CHIP_DATA,
          payload: values
        })
      }
    } catch (err) {

    }
  }
}

export const getChipData = () => {
  return async (dispatch) => {
    try {
      const chipData = JSON.parse(localStorage.getItem('chipData')) || [];
      if (chipData?.length > 0) {
        dispatch({
          type: collectionDataType.GET_CHIP_DATA,
          payload: chipData,
        })
        return chipData
      }
    } catch (err) {

    }
  }
}

export const createCharacter = (values) => {
  return async (dispatch) => {
    try {
      const characterData = JSON.parse(localStorage.getItem('characterData')) || []
      characterData.push(values)
      if (characterData) {
        localStorage.setItem('characterData', JSON.stringify(characterData))
        dispatch({
          type: collectionDataType.CREATE_CHARACTER_DATA,
          payload: values
        })
      }

    } catch { }
  }
}
export const getCharacterList = () => {
  return async (dispatch) => {
    try {
      const characterData = JSON.parse(localStorage.getItem('characterData')) || []
      if (characterData?.length > 0) {
        dispatch({
          type: collectionDataType.GET_CHARACTER_DATA,
          payload: characterData
        })
        return characterData
      }
    } catch { }
  }
}



export const deleteCharacterCollection = (id) => {
  return async (dispatch) => {
    try {
      const characterData = JSON.parse(localStorage.getItem("characterData")) || []
      if (characterData) {
        const newCharacterData = characterData.filter((item) => item?.id !== id)
        localStorage.setItem('characterData', JSON.stringify(newCharacterData))
        dispatch({
          type: collectionDataType.DELETE_CHARACTER_DATA,
          payload: id
        })
      }
    } catch { }
  }
}

export const getTraitCard = (values) => {
  return async (dispatch) => {
    callApi.get(`collections/${values.id}/traits`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: collectionDataType.GET_TRAIT_CARD_DATA,
            payload: {
              data: response.data.traits.data,
              id: values.traitID,
            },

          })
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }

}

export const deleteTraitCardItem = (values) => {
  return async (dispatch) => {
    callApi.get(`collections/${values.collectionID}/trait/${values.id}/delete`)
      .then((response) => {
        if (response.status === 200) {
          notyf.success(response.data.message)
          dispatch({
            type: collectionDataType.DELETE_TRAIT_CARD,
            payload: values,

          })
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}

export const createTraitCardItem = (data) => {
  return async (dispatch) => {
    callApi.post(`collections/${data.id}/trait/create`, data.values)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: collectionDataType.CREATE_TRAIT_CARD_COLLECTION,
            payload: response.data.trait.data,

          })
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}

export const dragAndDropTraitCard = (data) => {
  return async (dispatch) => {
    const dragAndDropValues = data?.values.map((item) => ({
      hash_id: item.id,
      position: item.position
    }))
    callApi.post(`collections/${data.id}/traits/update-position`, { positions: dragAndDropValues })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: collectionDataType.DRAG_AND_DROP_TRAIT_CARD,
            payload: data.values,

          })
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}


export const changeSelectedItem = (values) => {
  return async (dispatch) => {
    dispatch({
      type: collectionDataType.CHANGE_SELECTED_ITEM,
      payload: {
        ...values,
        images: values?.images[0]?.data,
        tags: values?.tags[0]?.data,
      },
    })
  }
}

export const createTraitTags = (data) => {
  return async (dispatch) => {
    callApi.post(`collections/${data.collectionID}/trait/${data.traitID}/tag`, data.values)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: collectionDataType.CREATE_TRAIT_TAGS,
            payload: {
              tag: response.data.tag.data,
              id: data.traitID
            },

          })
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}

export const deleteTraitTags = (data) => {
  return async (dispatch) => {
    callApi.get(`collections/${data.collectionID}/trait/${data.traitID}/tag/${data.tagID}/delete`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: collectionDataType.DELETE_TRAIT_TAGS,
            payload: {
              id: data.traitID,
              tagID: data.tagID
            },
          })
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}

export const saveAsDraft = (id) => async () => {
  callApi.get(`collections/${id}/draft`)
    .then((response) => {
      if (response.status === 200) {
        notyf.success(response.data.message)
      }
    })
    .catch((error) => {
      notyf.error(error.response.data.message)
      console.log(error, 'catch');
    });
}

export const updateRaritySettings = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch({
        type: collectionDataType.UPADATE_RARITY_SETTINGS,
        payload: data,
      })
    }
  }
}

export const updateImageVariationName = (data) => {
  return async (dispatch) => {
    callApi.post(`collections/${data.collectionID}/trait/${data.traitID}/images/${data.imageID}/variation`, data.values)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: collectionDataType.UPDATE_IMAGE_VARIATION_NAME,
            payload: {
              id: data.imageID,
              traitID: data.traitID,
              name: data.values.name
            },
          })
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}

export const updateTraitPosition = (data) => {
  return async (dispatch) => {
    callApi.post(`collections/${data.collectionID}/trait/${data.traitID}`, data.values)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: collectionDataType.UPDATE_TRAIT_POSITION,
            payload: {
              nextPosition: +data.values.position,
              prevPosition: data.traitPrevPosition,
              name: data.values.name,
              id: data.traitID,
            },
          })
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}

export const clearTraitsData = () => {
  return async (dispatch) => {
    dispatch({
      type: collectionDataType.CLEAR_TRAITS_DATA,
      payload: null,
    })
  }
}

export const clearImagesCollection = () => {
  return async (dispatch) => {
    dispatch({
      type: collectionDataType.CLEAR_IMAGES_COLLECTION,
      payload: null,
    })
  }
}

export const getPreviewCollection = (id) => {
  return async (dispatch) => {
    callApi.get(`collections/${id}/preview`)
      .then((response) => {
        if (response.status === 200 && !!response.data.images.length && !!response.data.images[0].length) {
          dispatch({
            type: collectionDataType.GET_PREVIEW_COLLECTION,
            payload: response.data.images,
          })
        }
      })
      .catch((error) => {
        console.log(error, 'catch');
      });
  }
}

export const paymentData = (data) => {
  return async () => {
    if (!data.values.coupon_code) {
      delete data.values.coupon_code
    }
    return callApi.post(`collections/${data.collectionId}/charge`, data.values)

  }
}



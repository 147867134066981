import styled from "styled-components";

export const TraitsRightMenu = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const TraitsRightMenuSettings = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    h4{
        width: 100%;
        font-family: 'Roboto Regular';
        font-style: normal;
        font-weight: 900;
        font-size: 10px;
        line-height: 20px;
        color: #A8BBCE;
        background: #2B2D34;
    }

    img{
        margin: 0 10px;
    }
`;
export const TraitsRightMenuForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    select{
        width: 247px;
        height: 33px;
        background: #F7F9FB;
        border: 1px solid #CAD6EC;
        border-radius: 8px;
        padding: 0 10px;
    }

    input{
        width: 247px;
        height: 33px;
        background: #F7F9FB;
        border: 1px solid #CAD6EC;
        border-radius: 8px;
        padding: 0 10px;
    }
`;

export const TraitsRightMenuLabel = styled.label`
    font-family: 'Roboto Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
    color: #CAD6EC;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;

    img{
        margin-left: 5px;
    }
`;

export const TraitsRightMenuButton = styled.button`
    box-sizing: border-box;
    width: 248px;
    height: 34px;
    background: #1F2328;
    border: 1px solid #E04558;
    border-radius: 8px;
    color: #E04558;
    margin-top: 20px;
    opacity:${props => props.disabled ? 0.4 : 1 };
`;

export const TraitsRightMenuTagInputBox = styled.div`
    box-sizing: border-box;
    width: 247px;
    background: #F7F9FB;
    border: 1px solid #CAD6EC;
    border-radius: 5px;

    .ReactTags__tags{
       display:none:
    }

    .ReactTags__selected{
        background: #F7F9FB;

        span{
            background: #1F2328;
            border-radius: 5px;
            padding: 5px;
            color:  #F7F9FB;
            margin: 5px 3px;


            button{
                color: #F7F9FB;
                border: none;
                background: #1F2328;
                border-radius: 30px;
            }

                :hover{
                    color: #939393
                }

            }
        }
    }


    input{
        width: 50%;
        height:33px;
        border: none;
        border-radius: 5px;
        outline: none;
        visibility:${props => props.disabledInput ? 'hidden' : 'visible'};
    }

`;

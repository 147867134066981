export const smartContractsType = {

    SHOW_NEXT_SMART_CONTRACT: "SHOW_NEXT_SMART_CONTRACT",

    SHOW_PREVIOUS_SMART_CONTRACT: "SHOW_PREVIOUS_SMART_CONTRACT",

    //REPORTING: "REPORTING"
    SHOW_MANAGE_CONTRACT: "SHOW_MANAGE_CONTRACT",
    SHOW_PUBLISHCOLLECTION: "SHOW_PUBLISHCOLLECTION",
    SHOW_MARKETINGKIT: "SHOW_MARKETINGKIT",
    SHOW_REPORTING: "SHOW_REPORTING",
    SHOW_ADVANCED: "SHOW_ADVANCED",
    SHOW_BASIC: "SHOW_BASIC",

}
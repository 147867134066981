import React, { useState } from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';
import { FaQuestionCircle } from "react-icons/fa";

function SelectBlockchain() {
    const [selected, setSelected] = useState({
        eth: false,
        poly: false,
        sol: false,
        car: false
    });

    return (
        <>
            <div className='slectBlockchain-main selectContacts-cont'>
                <h3 className=''>Select the blockchain that your collection will use.</h3>
                <div className='page-devider'></div>
                <div className='selectBlockchain-cont'>
                    <h5>SELECT BLOCKCHAIN <span>*</span></h5>
                    <div className='slectchainlogo-main'>
                        <div className={selected?.eth === true ? 'slectchainlogo active' : 'slectchainlogo'} value={selected} onClick={() => setSelected({ eth: true })} >
                            <img src="/images/ethereum-logo.png" alt="" />
                        </div>
                        <div className={selected?.poly === true ? 'slectchainlogo active' : 'slectchainlogo'} value={selected} onClick={() => setSelected({ poly: true })} >
                            <img src="/images/polygon-logo.png" alt="" />
                        </div>
                        <div className={selected?.sol === true ? 'slectchainlogo active' : 'slectchainlogo'} value={selected} onClick={() => setSelected({ sol: true })}  >
                            <img src="/images/solana-logo.png" alt="" />
                        </div>
                        <div className={selected?.car === true ? 'slectchainlogo active' : 'slectchainlogo'} value={selected} onClick={() => setSelected({ car: true })}  >
                            <img src="/images/cardano-logo.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='selectStandard-cont'>
                <h5>SELECT STANDARD <span>*</span></h5>
                <div className='slectchainlogo-main'>
                    <div className='slectchainlogo active'>
                        <img src="/images/ERC-721-logo.png" alt="" />
                    </div>
                    <div className='slectchainlogo'>
                        <img src="/images/ERC-1155-logo.png" alt="" />
                    </div>
                </div>
            </div> */}
            <div className='collectionDetails-main selectContacts-cont'>
                {/* <h3>Provide basic details about your NFT collection.</h3> */}
                {/* <div className='page-devider'></div> */}
                <div className='collectionDetails-cont'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-8 col-sm-12'>
                            <form className='collectionDetails-form'>
                                <label htmlFor="CollectionName">
                                    <label>COLLETION NAME <span>*</span></label>
                                    <input
                                        name="CollectionName"
                                        type="text"
                                        placeholder="Happy Clowns"
                                    />
                                </label>
                                <label htmlFor="CollectionFamily">
                                    <label>COLLETION FAMILY <FaQuestionCircle className='quetionmakrIcon' /></label>
                                    <input
                                        name="CollectionFamily"
                                        type="text"
                                        placeholder="Eg. Sports Edition"
                                    />
                                </label>
                                <label htmlFor="TraitDescription">
                                    <label>COLLETION DESCRIPTION <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                    <textarea
                                        name="TraitDescription"
                                        type="text"
                                        placeholder="A collection of 10,000 unique clowns ready to take the metaverse by storm."
                                    />
                                </label>
                                <label htmlFor="ContractSymbol">
                                    <label>CONTRACT SYMBOL  <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                    <input
                                        name="ContractSymbol"
                                        type="text"
                                        placeholder="Eg. Sports Edition"
                                    />
                                </label>
                                {/* <label htmlFor="NFTname">
                                    <label>NFT NAME <span>*</span><img src=src={hoverImage} onMouseOver={() => setHoverImage(traitHoverIcon)} onMouseOut={() => setHoverImage(traitIconimg)} alt="" /></label>
                                    <input
                                        name="NFTname"
                                        type="text"
                                        placeholder="Happy Clowns"
                                    />
                                </label> */}
                                <label htmlFor="CollectionWebsite">
                                    <label>COLLECTION WEBSITE <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                    <input
                                        name="CollectionWebsite"
                                        type="text"
                                        placeholder="Eg. https://nftclowns.com"
                                    />
                                </label>
                                <label htmlFor="CollectionSupply">
                                    <label>COLLECTION SUPPLY  <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                    <input
                                        name="CollectionSupply"
                                        type="text"
                                        placeholder="10,000"
                                    />
                                </label>
                                <div className='trait-switch'>
                                    <label>COLLECT ROYALTY <FaQuestionCircle className='quetionmakrIcon' /></label>
                                    <div className="toggle-button-cover">
                                        {/* <div className="button-cover"> */}
                                            {/* <div className="button b2" id="button-10"> */}
                                            {/* <input type="checkbox" className="checkbox" />
                                                <div className="knobs">
                                                    <span>Yes</span>
                                                </div> */}
                                            {/* <div className="layer"></div> */}
                                            {/* <div className="mt-5 mb-5"> */}
                                                <Tabs defaultActiveKey="yesRairty" id="uncontrolled-tab-example" className="mb-3">
                                                    <Tab eventKey="yesRairty" title="Yes">
                                                        <Form.Group className="mt-2 ">
                                                            <Form.Label> ROYALTY AMOUNT <span>*</span></Form.Label>
                                                            <input type='number' placeholder='3.00%' className='royaltyAmount mb-0' />
                                                        </Form.Group>
                                                    </Tab>
                                                    <Tab eventKey="noRairty" title="No">
                                                    </Tab>
                                                </Tabs>
                                            {/* </div> */}
                                            {/* </div> */}

                                        {/* </div> */}
                                    </div>
                                </div>

                                <label htmlFor="royaltyWalletAddress">
                                    <label>ROYALTY WALLET ADDRESS <span>*</span><FaQuestionCircle className='quetionmakrIcon' /></label>
                                    <input
                                        name="royaltyWalletAddress"
                                        type="text"
                                        placeholder="Eg. https://nftclowns.com"
                                    />
                                </label>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectBlockchain


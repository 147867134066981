import React from 'react';
import { useDispatch } from 'react-redux';
import * as actions from "../../redux/action"
const ReportingMenu = () => {
    const dispatch = useDispatch()
    const onManageContract = () => {
        dispatch(actions.showManageContract())
    }
    const onPublishCollection = () => {
        dispatch(actions.showpublishCollection())
    }
    const onMarketingKit = () => {
        dispatch(actions.showMarketingKit())
    }
    const onReporting = () => {
        dispatch(actions.showReporting())
    }

    return (
        <>
            <div className='left-menu-main smart-left-menus'>
                <div className='dashboard-collection'>
                    <img src='/images/image.png' alt="" />
                    <div className='dashboard-collection-cont'>
                        <div className='dashboard-collection-cont-list'>
                            <span className=''>
                                COLLECTION NAME
                            </span>
                            <h4>Happy Apes</h4>
                        </div>
                        <div className='dashboard-collection-cont-list'>
                            <span>
                                COLLECTION SIZE
                            </span>
                            <h4>10,000</h4>
                        </div>
                    </div>
                </div>
                <div className='dashboard-links join-dash-main'>
                    <button className='withdrow-btn ' onClick={() => onManageContract()}>
                        MANAGE CONTRACT
                    </button>
                    <button className='withdrow-btn' onClick={() => onPublishCollection()} >
                        PUBLISH COLLECTION
                    </button>
                    <button className='withdrow-btn' onClick={() => onMarketingKit()}>
                        MARKETING KIT
                    </button>
                    <button className='withdrow-btn' onClick={() => onReporting()}>
                        REPORTING
                    </button>
                </div>
            </div >
        </>
    )

};

export default ReportingMenu;

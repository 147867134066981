import React from 'react';
import { Modal } from 'react-bootstrap';
import 'react-rangeslider/lib/index.css'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';


function ConfirmOrderModal({
  confirmShowModal,
  setConfirmShowModal,
  collectionId,
  collectionArchive,
  exportStatusDataCollection,
  uniqueImages,
  checked,
  setChecked,
  setDataSuccsess,
  handleConfirmExport,
  handelClick,
  disable
}) {


  const stripeObject = loadStripe("pk_test_519GQj3F6DApugaloK4hZH9mkUvN0y7w2ud0fTJQFHYA4tdqHdxbYYRYg2Md9Bvij5wAwSCGcwdOjBxNrnV4mYUmP00rIpZNPtz");

  return (
    <>
      <Modal show={confirmShowModal} onHide={() => setConfirmShowModal(!confirmShowModal)} className='confirm-order-modal-main confirm-order-modal'>
        <span className='crossModal' onClick={() => setConfirmShowModal(!confirmShowModal)}>&times;</span>
        <Modal.Body>
          <Elements stripe={stripeObject}>
            <CheckoutForm
              confirmShowModal={confirmShowModal}
              setConfirmShowModal={setConfirmShowModal}
              collectionId={collectionId}
              collectionArchive={collectionArchive}
              exportStatusDataCollection={exportStatusDataCollection}
              uniqueImages={uniqueImages}
              checked={checked}
              setChecked={setChecked}
              setDataSuccsess={setDataSuccsess}
              handleConfirmExport={handleConfirmExport}
              handelClick={handelClick}
              disable={disable}
            />
          </Elements>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConfirmOrderModal

import React from 'react';
import { Modal } from 'react-bootstrap';

function DeployModalFile({ show, setShow }) {
    const testnet = localStorage.getItem('testnet');

    return (
        <>
            <Modal show={show} onHide={() => { setShow(!show); setTimeout(() => { localStorage.removeItem('testnet') }, 1000) }} className='delete-modal-main smart-deploy-modal'>
                <Modal.Body>
                    <div className='delete-modal-content'>
                        {!testnet ?
                            <h2>Confirm Smart Contract</h2> :
                            <h2>Embed Mint Button</h2>
                        }
                        <div className='smart-modal-devider'></div>

                        <div className='smart-deploy-modal-cont'>
                            {testnet ?
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
                                    </li>
                                </ul>
                                :
                                ''
                            }
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    {!testnet ?
                                        <form >
                                            <div className="form-group form-check confirm-smart-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" /> By clicking the checkbox, I acknowledge that I have audited the smart contract and I agree to the terms and conditions.   I understand that once my contract is published
                                                </label>
                                            </div>
                                            <button className='btn-deploy'>DEPLOY SMART CONTRACT</button>
                                        </form>
                                        :
                                        <>
                                            <p>Step 1: Add the following javascript code at the end of the  tag on the page you are adding your mint button to.</p>
                                            <button className='btn-deploy'>COPY</button>
                                            <p>Step 2: Add the following javascript code where you would like the button to display on your page.</p>
                                            <button className='btn-deploy'>COPY</button>
                                        </>
                                    }
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">bye</div>
                            </div>



                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeployModalFile
